import React, { useEffect, useState } from 'react'
import { BrowserRouter, Routes,Route} from 'react-router-dom'

import Sidebar from '../components/Sidebar'

import Dashboard from './Dashboard'
import Saisie from './Saisie'
import Rma from './Rma'
import Client from './Client'
import Entite from './Entite'
import Utilisateur from './Utilisateur'
import Fir from './Fir'
import Navex101 from './Navex101'
import RapportPaxFret from './RapportPaxFret'
import RapportRoute from './RapportRoute'
import SaisieDoubleFormulaire from './SaisieDoubleFormulaire'
import _404 from './_404'


import Header from './../components/Header';
const Home = ({ page }) => {
    
   
    useEffect  (()=>{
        //if(!localStorage.getItem("userProfil"))window.location.href="/";
    },[]);

    return (
        // <BrowserRouter>
        //     <Routes>
        //         <Route index element={<Home page='dashboard' />} />
        //         <Route path="/login" element="login" />
        //         <Route path="/home" element={<Login page="dashboard" />} />
        //         <Route path="/saisie" element={<Home page="saisie" />} />
        //         <Route path="/saisieDoubleFormulaire" element={<Home page="saisieDoubleFormulaire" />} />
        //         <Route path="/listeMouvements" element={<Home page="listeMouvements" />} />
        //         <Route path="/updateMouvement" element={<Home page="saisiemouvement" />} />
        //         <Route path="/menu" element="menu" />

        //     </Routes>
        // </BrowserRouter>
        <BrowserRouter>
        <div className='flex flex-row bg-slate-300'>

            <div className="h-screen w-[320px] border-r-1 border-gray-200  bg-[#001528] py-4 flex flex-col align-middle">
                <Sidebar />
            </div>
            <div className=' w-full flex-auto'>
                <div className="bg-blue-500">
                    <Header />
                </div>
                <div className='px-3 py-0.5'>
                    {/* <h4 className="text-xl">{titre} </h4> */}
                    {/* {//cmpnt} */}
                    <Routes>
                        <Route path="/home" element={<Dashboard />} />
                        <Route path="/saisie" element={<Saisie />} />
                        <Route path="/rma" element={<Rma />} />
                        <Route path="/rapportNavex101" element={<Navex101 />} />
                        <Route path="/rapportPaxFret" element={<RapportPaxFret />} />
                        <Route path="/rapportRoute" element={<RapportRoute />} />
                        <Route path="/saisieDoubleFormulaire" element={<SaisieDoubleFormulaire />} />
                        <Route path="/listeMouvements" element={<Dashboard />} />
                        <Route path="/updateMouvement" element={<Dashboard />} />
                        <Route path="/menu" element="menu" />
                        <Route path="*" element={<Dashboard />} />

                    </Routes>
                </div>
            </div>
        </div>
        </BrowserRouter>
    )
}

export default Home