import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select,notification ,Modal} from 'antd';
import 'antd/dist/antd.css';
import { useToast } from '@chakra-ui/react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Box, Paper, Backdrop, CircularProgress,Snackbar,AlertTitle} from '@mui/material';
import MuiAlert from '@mui/material/Alert';

import FlightLandIcon from '@mui/icons-material/FlightLand';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import DoneAllIcon from '@mui/icons-material/DoneAll';
import CloseIcon from '@mui/icons-material/Close';

import { useStateContext } from './../contexts/ContextProvider';
import { useForm,Controller, set } from 'react-hook-form'
import moment from 'moment';
import { useNavigate } from 'react-router-dom';


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Saisie({windowState}) {
    
    ///windowState && alert("ttt");
    const [tabDepart,setTabDepart]=useState(false);
    
    const [toastIsOpened,setToastIsOpened]=useState(false);
    const { handleSubmit, register,setValue:setValueHook,getValues,setError, formState: { errors } } = useForm();
    
    let coucheeLevee=[];
    const [value, setValue] = React.useState(0);

    const [numFtLong, setNumFtLong] = useState("");

    const [exploitantValue, setExploitantValue] = useState("");
    // const [dateArrivee, setDateArrivee] = useState("");
    // const [heureArrivee, setHeureArrivee] = useState("");
    const [flArrivee, setFlArrivee] = useState("");
    const [provenanceArrivee, setProvenanceArrivee] = useState("");
    const [provenanceDepart, setProvenanceDepart] = useState("");
    const [ptEntIsDisabled, setPtEntIsDisabled] = useState(true);
    const [ptSortIsDisabled, setPtSortIsDisabled] = useState(true);

    // IMMATRICULATIONS,FIRS,VILLES,CLIENTS
    const [immatriculations, setImmatriculation] = useState([]);
    const [firs, setFirs] = useState([]);

    const [esc1FirA,setEsc1FirA] = useState("");
    const [esc2FirA,setEsc2FirA] = useState("");
    const [esc3FirA,setEsc3FirA] = useState("");
    const [esc4FirA,setEsc4FirA] = useState("");
    
    const [esc1FirD,setEsc1FirD] = useState("");
    const [esc2FirD,setEsc2FirD] = useState("");
    const [esc3FirD,setEsc3FirD] = useState("");
    const [esc4FirD,setEsc4FirD] = useState("");

    const [villes, setVilles] = useState([]);
    const [villes1, setVilles1] = useState([]);
    const [villes2, setVilles2] = useState([]);
    const [villes3, setVilles3] = useState([]);
    const [villes4, setVilles4] = useState([]);
    const [villesD, setVillesD] = useState([]);
    const [villes1D, setVilles1D] = useState([]);
    const [villes2D, setVilles2D] = useState([]);
    const [villes3D, setVilles3D] = useState([]);
    const [villes4D, setVilles4D] = useState([]);
    const [clients, setClients] = useState([]);
    const [aeronefs, setAeronefs] = useState([]);
    const [coucheeLevees, setCoucheeLevees] = useState([]);

    const [state, setState] = React.useState({
        title:"",
        status:"success",
        openToast: false,
        vertical: 'top',
        horizontal: 'center',
      });
      const { vertical, horizontal } = state;
    
    const closeToast=()=>{
        setToastIsOpened(false);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [getimmatriculation,setGetimmatriculation]=useState("");
    const selectImmatriculation=(e)=>{
        setGetimmatriculation(e);
        if(e==null || e==0){
           
            e=0;
        } 
        const client=clients.find(c=>{return c.IdCl==immatriculations.find(i=>{return i.IdImm==e}).Proprietaire}); 
        const aeronef=aeronefs.find(a=>{return a.Id_typ==immatriculations.find(i=>{return i.IdImm==e}).TypeAero});    
        //const Proprietaire=clients.find(c=>{return c.IdCl==immatriculations.find(i=>{return i.IdImm}).Proprietaire});        
        setValueHook("proprietaire",client.NomCl);
        setValueHook("typeAero",aeronef.Libelle_typ);
        setValueHook("pma",aeronef.Mtow);  
        setExploitantValue(immatriculations.find(i=>{return i.IdImm==e}).Proprietaire);
        tabulationBySelect("exploitant");
    }
   
    const selectProvenanceArrivee=(e)=>{
        setValueHook("provenanceDetailArrivee",villes.find(f=>{return f.IdFir==e}).LibelleFir);
        setValueHook("ptEntree",null);
        const ville=villes.find(f=>{return f.IdFir==e});
        if(ville.CodeFir.substring(0,2)!=="FZ"){
            setPtEntIsDisabled(false);
            document.querySelector("#ptEntree").disabled=false;
            document.querySelector("#ptEntree").focus();
        }else{
            document.querySelector("#ptEntree").disabled=true;
            document.querySelector("#paxAdArrivee").focus();
        }
        setProvenanceArrivee(ville.CodeFir);
        //alert(ville.CodeFir);
        setVilles1(villes.filter(v=>{return v.CodeFir!=ville.CodeFir}));
        if(parseInt(e)>0)
        {
            setTabDepart(true);
        }else
        {
            setTabDepart(false);
        }
    }
    const selectProvenanceDepart=(e)=>{
        
        setValueHook("provenanceDetailDepart",villes.find(f=>{return f.IdFir==e}).LibelleFir);
        setValueHook("ptSortie",null);
        const ville=villes.find(f=>{return f.IdFir==e});
        ville.CodeFir.substring(0,2)!=="FZ"?setPtSortIsDisabled(false):setPtSortIsDisabled(true);
        setProvenanceDepart(ville.CodeFir);
        //alert(ville.CodeFir);
        setVilles1D(villesD.filter(v=>{return v.CodeFir!=ville.CodeFir}));
        // if(ville.CodeFir.subStr)
    }
    
    // set value for default selection
    const [selectedValue, setSelectedValue] = useState(3);

    const { Option } = Select;

    // handle onChange event of the dropdown
    
    const options = [
        {
            value: 1,
            label: "cerulean"
        },
        {
            value: 2,
            label: "fuchsia rose"
        },
        {
            value: 3,
            label: "true red"
        },
        {
            value: 4,
            label: "aqua sky"
        },
        {
            value: 5,
            label: "tigerlily"
        },
        {
            value: 6,
            label: "blue turquoise"
        }
    ];
    
    const wrapperLabel = "w-28 text-12 text-right pr-2";

    const { api } = useStateContext();
    
    const [_checkEscaleArrivee, set_CheckEscaleArrivee] = useState(null);
    const [_checkEscaleDepart, set_CheckEscaleDepart] = useState(null);
    const [escaleContainerVisibleArrivee, setEscaleContainerVisibleArrivee] = useState("none");
    const [escaleContainerVisibleDepart, setEscaleContainerVisibleDepart] = useState("none");
    const [escale1VisibleArrivee, setEscale1VisibleArrivee] = useState("none");
    const [escale1VisibleDepart, setEscale1VisibleDepart] = useState("none");
    const [escale2VisibleArrivee, setEscale2VisibleArrivee] = useState("none");
    const [escale2VisibleDepart, setEscale2VisibleDepart] = useState("none");
    const [escale3VisibleArrivee, setEscale3VisibleArrivee] = useState("none");
    const [escale3VisibleDepart, setEscale3VisibleDepart] = useState("none");
    const [escale4VisibleArrivee, setEscale4VisibleArrivee] = useState("none");
    const [escale4VisibleDepart, setEscale4VisibleDepart] = useState("none");
    const [escale5VisibleArrivee, setEscale5VisibleArrivee] = useState("none");
    const [escale5VisibleDepart, setEscale5VisibleDepart] = useState("none");

    const onChangeNumFt=(e)=>{
        e.target.value=e.target.value.toUpperCase()
    }

    //CONTROLE AJOUT ESCALES
    const [showEscalesContainer,setShowEscalesContainer]=useState(false);
    const [arriveeEsc2Container,setArriveeEsc2Container]=useState(false);
    const [arriveeEsc3Container,setArriveeEsc3Container]=useState(false);
    const [arriveeEsc4Container,setArriveeEsc4Container]=useState(false);

    const [showDepartEscalesContainer,setShowDepartEscalesContainer]=useState(false);
    const [showEsc2Dep,setShowEsc2Dep]=useState(false);
    const [showEsc3Dep,setShowEsc3Dep]=useState(false);
    const [showEsc4Dep,setShowEsc4Dep]=useState(false);

    const [datas, setDatas] = useState([]);
    const onSearch = (searchText) => {
        setDatas(options);
    };

    

    const onChange = (data) => {
        setValueHook('immatriculation',data);
    };
    //POUR BACKDROP
    const [open, setOpen] = React.useState(false);
    //FIRS ESCALES
    
    const [esc1FirArrivee, setEsc1FirArrivee] = useState([]);
    const [esc2FirArrivee, setEsc2FirArrivee] = useState([]);
    const [esc3FirArrivee, setEsc3FirArrivee] = useState([]);
    const [esc4FirArrivee, setEsc4FirArrivee] = useState([]);
    const [esc5FirArrivee, setEsc5FirArrivee] = useState([]);

    //FIR ESCALES SELECTIONNES
   
    const [provenance1, setProvenance1] = useState();
    const [provenance2, setProvenance2] = useState("");
    const [provenance3, setProvenance3] = useState("");
    const [provenance4, setProvenance4] = useState("");
    const [provenance5, setProvenance5] = useState("");

    // 
    const [showHT,setShowHT]=useState(false);
    const [showHG,setShowHG]=useState(false);

    //CONTROL ESCALES
    const onChangeEscale1Arrivee = (value) => {
        // console.log("Avant ", value);
        // setProvenance1(value);
        // console.log("Apres ", provenanceUn);
        // const es2 = esc1FirArrivee.filter(v => !v.IdFir.includes(value));
        // setEsc2FirArrivee(es2);
    }
    useEffect(() => {
        const es2 = esc2FirArrivee.filter(v => !v.IdFir.includes(provenance1));
        setEsc2FirArrivee([]);
    }, [provenance1]);
    
    const [showStationnementEnt,setShowStationnementEnt]=useState(false);
    const [showStationnementSort,setShowStationnementSort]=useState(false);
    const selectStationnement=(e)=>{
        if(e=="G-T" || e=="ZH-T" || e=="T-ZH")
        {
            setShowHT(true);
            setShowHG(false);
            setShowStationnementEnt(true);
            setShowStationnementSort(false);
        }else if(e=="T-G-T" || e=="T-ZH-T"){
            setShowHG(true);
            setShowHT(true);
            setShowStationnementEnt(true);
            setShowStationnementSort(true);
        }else
        {
            setShowHG(false);
            setShowHT(false);

            setShowStationnementEnt(false);
            setShowStationnementSort(false);
        }
    }

    useEffect(() => {

        let data = new FormData();
        data.append("qry", "initDatas");
        fetch(api, { method: "POST", body: data }).then(r => r.json())
            .then(r => {
                setImmatriculation(r.immatriculations);
                setClients(r.clients);
                setFirs(r.firs);
                setVilles(r.villes);
                setVilles1(r.villes);
                setVilles2(r.villes);
                setVilles3(r.villes);
                setVilles4(r.villes);
                setVillesD(r.villes);
                setVilles2D(r.villes);
                setVilles3D(r.villes);
                setVilles4D(r.villes);
                setAeronefs(r.aeronefs);
                setCoucheeLevees(r.coucheeLevees);

                setEsc1FirArrivee(r.villes);
                setEsc2FirArrivee(r.villes);
                setEsc3FirArrivee(r.villes);
                setEsc4FirArrivee(r.villes);
                setEsc5FirArrivee(r.villes);
               
            })
            .catch(r => {});


        setOpen(false);
    }, []);

    //Pour couchée-levéé du soleil
    useEffect(()=>{
        let d = new FormData();
        d.append("qry", "getCoucheeLevee");
        fetch(api, { method: "POST", body: d }).then(r => r.json())
            .then(r => {
                coucheeLevee=r;
            }).catch(e=>{
                console.log("erreur",e);
            });
    },[]);

    const [categVolArrivee,setCategVolArrivee]=useState("COM");
    const [categVolDepart,setCategVolDepart]=useState("COM");
    const [stationnement,setStationnement]=useState("T");

    const [ptEntree,setPtEntree]=useState("");
    const selectPtEntree=(e)=>{
        setPtEntree(e);
        document.querySelector("#paxAdArrivee");
    }
    const [ptSortie,setPtSortie]=useState("");
    const selectPtSortie=(e)=>{
        setPtSortie(e);
    }

    



    const onSubmit = () => {
        
      let error = false;
      let errorDetail = "";
      if (getValues("numFt") == "") {
        error = true;
        errorDetail = "veuillez completez le formulaire de trafic";
      }
      if (getimmatriculation == "") {
        error = true;
        errorDetail = "veuillez selectionner l'immatriculation";
      }
      if (getValues("exploitant") == "") {
        error = true;
        errorDetail = "veuillez saisir l'exploitant";
      }

      if (getValues("dateArrivee") == "") {
        error = true;
        errorDetail = "veuillez saisir la date d'arrivée";
      }
      if (getValues("fretArrivee") == "") {
        setValueHook("fretArrivee", 0);
      }
      if (getValues("heureArrivee") == "") {
        error = true;
        errorDetail = "veuillez saisir l'heure d'arrivée";
      }
      if (getValues("excBagArrivee") == "") {
        setValueHook("excBagArrivee", 0);
      }
      if (getValues("flArrivee") == "") {
        error = true;
        errorDetail = "veuillez saisir le niveau de vol";
      }
      if (getValues("fretTransArrivee") == "") {
        setValueHook("fretTransArrivee", 0);
      }
     //alert(provenanceArrivee);
      if (provenanceArrivee == "") {
        error=true;
        errorDetail="Veuillez selectionner la provenance du vol";
      } else {
        let provenance = provenanceArrivee;
        if (provenance.substr(0, 2) !== "FZ") {
          if (ptEntree == "") {
            error = true;
            errorDetail = "Veuillez selectionner le point d'entrée";
          }
        }
      }
      if (getValues("posteArrivee") == "") {
        setValueHook("postArrivee", 0);
      }
      if (getValues("paxEnfArrivee") == "") {
        setValueHook("paxEnfArrivee ", 0);
      }
      if (getValues("paxBebArrivee") == "") {
        setValueHook("paxBebArrivee ", 0);
      }
      if (getValues("paxTransArrivee") == "") {
        setValueHook("paxTransArrivee ", 0);
      }


      //Control depart
      if (getValues("dateDepart") == "") {
        error = true;
        errorDetail = "veuillez saisir la date du depart";
      }
      if (getValues("fretDepart") == "") {
        setValueHook("fretDepart", 0);
      }
      if (getValues("heureDepart") == "") {
        error = true;
        errorDetail = "veuillez saisir l'heure du depart";
      }
      if (getValues("excBagDepart") == "") {
        setValueHook("excBagDepart", 0);
      }
      if (getValues("flDepart") == "") {
        error = true;
        errorDetail = "veuillez saisir le niveau de vol au depart";
      }
      if (getValues("fretTransDepart") == "") {
        setValueHook("fretTransDepart", 0);
      }
     
      if (provenanceDepart == "") {
        error=true;
        errorDetail="Veuillez selectionner la destination du vol";
      } else {
        let destination = provenanceDepart;
        if (destination.substr(0, 2) !== "FZ") {
          if (ptSortie == "") {
            error = true;
            errorDetail = "Veuillez selectionner le point fir de sortie";
          }
        }
      }
      if (getValues("posteDepart") == "") {
        setValueHook("posteDepart", 0);
      }
      if (getValues("paxEnfDepart") == "") {
        setValueHook("paxEnfDepart ", 0);
      }
      if (getValues("paxBebDepart") == "") {
        setValueHook("paxBebDepart ", 0);
      }
      if (getValues("paxTransDepart") == "") {
        setValueHook("paxTransDepart ", 0);
      }
      if (getValues("compt") == "") {
        setValueHook("compt ", 1);
      }
      if (getValues("ft") == "") {
        setValueHook("ft ", 2);
      }
      if(stationnement=="G-T" || stationnement=="ZH-T" || stationnement=="T-ZH")
      {
        if(getValues("dtEntreeGarage")=="")
        {
            error=true;
            errorDetail="Veuillez saisir la date d'entrée (G ou T ou ZT)";
        }
        if(getValues("heureEntreeGarage"))
        {
            error=true;
            errorDetail="Veuillez saisir l'heure d'entrée (G ou T ou ZT)";
        }
      }
      if(stationnement=="T-G-T" || stationnement=="T-ZH-T")
      {
        if(getValues("dtEntreeGarage")=="")
        {
            error=true;
            errorDetail="Veuillez saisir la date d'entrée (G ou T ou ZT)";
        }
        if(getValues("heureEntreeGarage"))
        {
            error=true;
            errorDetail="Veuillez saisir l'heure d'entrée (G ou T ou ZT)";
        }

        if(getValues("dtSortieGarage")=="")
        {
            error=true;
            errorDetail="Veuillez saisir la date de sortie (G ou T ou ZT)";
        }
        if(getValues("heureSortieGarage"))
        {
            error=true;
            errorDetail="Veuillez saisir l'heure de sortie (G ou T ou ZT)";
        }
      }

      //Control depart


      if (!error) {
        Modal.confirm({
          title: "Enregistrer",
          content: "Voulez-vous vraiment enregistrer ce mouvement ?",
          okText: "Oui",
          cancelText: "Annuler",
          onOk: () => {
            let datas=new FormData();
            datas.append("qry","mouvementAD");
            datas.append("numft",getValues("numFt"));
            datas.append("Immatriculation",getimmatriculation);
            datas.append("vExploitant",exploitantValue);
            
            datas.append("vDate",getValues("dateArrivee"));
            datas.append("fret",getValues("fretArrivee"));
            datas.append("vHeure",getValues("heureArrivee"));
            datas.append("excBag",getValues("excBagArrivee"));
            datas.append("vFl",getValues("flArrivee"));
            datas.append("fretTrans",getValues("fretTransArrivee"));
            datas.append("fretPost",getValues("posteArrivee"));
            datas.append("provenance",provenanceArrivee);
            datas.append("pilote",getValues("piloteArrivee"));
            datas.append("categVol",categVolArrivee);
            datas.append("ptEnt_ptSort",ptEntree);
            datas.append("PaxAd",getValues("paxAdArrivee"));
            datas.append("PaxEnf",getValues("paxEnfArrivee"));
            datas.append("PaxBeb",getValues("paxBebArrivee"));
            datas.append("corresp",getValues("paxCorrArrivee"));
            datas.append("trans",getValues("paxTransArrivee"));
            datas.append("dtentree","");
            datas.append("heureentre","");
            datas.append("dtretour","");
            datas.append("heureretour","");
            datas.append("chkBalisage",getValues("balisageArrivee"));
            datas.append("chkAssAntInc","");
            
            datas.append("escale1Fir",esc1FirA);
            datas.append("esc1Ad",getValues("adEsc1Arrivee"));
            datas.append("esc1Enf",getValues("enfEsc1Arrivee"));
            datas.append("esc1Beb",getValues("bebEsc1Arrivee"));
            datas.append("esc1PaxTrans",getValues("paxTransEsc1Arrivee"));
            datas.append("esc1FretKg",getValues("fretKgEsc1Arrivee"));
            datas.append("esc1ExcBag",getValues("excBagEsc1Arrivee"));
            datas.append("esc1FretTrans",getValues("fretTransEsc1Arrivee"));
            datas.append("esc1Poste",getValues("postEsc1Arrivee"));
            
            datas.append("escale2Fir",esc2FirA);
            datas.append("esc2Ad",getValues("adEsc2Arrivee"));
            datas.append("esc2Enf",getValues("enfEsc2Arrivee"));
            datas.append("esc2Beb",getValues("bebEsc2Arrivee"));
            datas.append("esc2PaxTrans",getValues("paxTransEsc2Arrivee"));
            datas.append("esc2FretKg",getValues("fretKgEsc2Arrivee"));
            datas.append("esc2ExcBag",getValues("excBagEsc2Arrivee"));
            datas.append("esc2FretTrans",getValues("fretTransEsc2Arrivee"));
            datas.append("esc2Poste",getValues("postEsc2Arrivee"));
           
            datas.append("escale3Fir",esc3FirA);
            datas.append("esc3Ad",getValues("adEsc3Arrivee"));
            datas.append("esc3Enf",getValues("enfEsc3Arrivee"));
            datas.append("esc3Beb",getValues("bebEsc3Arrivee"));
            datas.append("esc3PaxTrans",getValues("paxTransEsc3Arrivee"));
            datas.append("esc3FretKg",getValues("fretKgEsc3Arrivee"));
            datas.append("esc3ExcBag",getValues("excBagEsc3Arrivee"));
            datas.append("esc3FretTrans",getValues("fretTransEsc3Arrivee"));
            datas.append("esc3Poste",getValues("postEsc3Arrivee"));
            
            datas.append("escale4Fir",esc4FirA);
            datas.append("esc4Ad",getValues("adEsc4Arrivee"));
            datas.append("esc4Enf",getValues("enfEsc4Arrivee"));
            datas.append("esc4Beb",getValues("bebEsc4Arrivee"));
            datas.append("esc4PaxTrans",getValues("paxTransEsc4Arrivee"));
            datas.append("esc4FretKg",getValues("fretKgEsc4Arrivee"));
            datas.append("esc4ExcBag",getValues("excBagEsc4Arrivee"));
            datas.append("esc4FretTrans",getValues("fretTransEsc4Arrivee"));
            datas.append("esc4Poste",getValues("postEsc4Arrivee"));

            // Depart
            datas.append("vDateD",getValues("dateDepart"));
            datas.append("fretD",getValues("fretDepart"));
            datas.append("vHeureD",getValues("heureDepart"));
            datas.append("excBagD",getValues("excBagDepart"));
            datas.append("vFlD",getValues("flDepart"));
            datas.append("fretTransD",getValues("fretTransDepart"));
            datas.append("fretPostD",getValues("posteDepart"));
            datas.append("provenanceD",provenanceDepart);
            datas.append("piloteD",getValues("piloteDepart"));
            datas.append("categVolD",categVolDepart);
            datas.append("ptEnt_ptSortD",ptSortie);
            datas.append("PaxAdD",getValues("paxAdDepart"));
            datas.append("PaxEnfD",getValues("paxEnfDepart"));
            datas.append("PaxBebD",getValues("paxBebDepart"));
            datas.append("correspD",getValues("paxCorresp"));
            datas.append("transD",getValues("paxTrans"));
            datas.append("typeStatD",stationnement)
            datas.append("dtentree",getValues("dtEntreeGarage"));
            datas.append("heureentre",getValues("heureEntreeGarage"));
            datas.append("dtretour",getValues("dtSortieGarage"));
            datas.append("heureretour",getValues("heureSortieGarage"));
            datas.append("ft",getValues("ft"));
            datas.append("compt",getValues("compt"));
            datas.append("chkBalisageD",getValues("balisageDepart"));
            datas.append("chkAssAntIncD",getValues("aisDepart"));
            
            datas.append("escale1FirD",esc1FirD);
            datas.append("esc1AdD",getValues("adEsc1Depart"));
            datas.append("esc1EnfD",getValues("enfEsc1Depart"));
            datas.append("esc1BebD",getValues("bebEsc1Depart"));
            datas.append("esc1PaxTransD",getValues("paxTransEsc1Depart"));
            datas.append("esc1FretKgD",getValues("fretKgEsc1Depart"));
            datas.append("esc1ExcBagD",getValues("excBagEsc1Depart"));
            datas.append("esc1FretTransD",getValues("fretTransEsc1Depart"));
            datas.append("esc1PosteD",getValues("postEsc1Depart"));
            
            datas.append("escale2FirD",esc2FirD);
            datas.append("esc2AdD",getValues("adEsc2Depart"));
            datas.append("esc2EnfD",getValues("enfEsc2Depart"));
            datas.append("esc2BebD",getValues("bebEsc2Depart"));
            datas.append("esc2PaxTransD",getValues("paxTransEsc2Depart"));
            datas.append("esc2FretKgD",getValues("fretKgEsc2Depart"));
            datas.append("esc2ExcBagD",getValues("excBagEsc2Depart"));
            datas.append("esc2FretTransD",getValues("fretTransEsc2Depart"));
            datas.append("esc2PosteD",getValues("postEsc2Depart"));
           
            datas.append("escale3FirD",esc3FirD);
            datas.append("esc3AdD",getValues("adEsc3Arrivee"));
            datas.append("esc3EnfD",getValues("enfEsc3Arrivee"));
            datas.append("esc3BebD",getValues("bebEsc3Arrivee"));
            datas.append("esc3PaxTransD",getValues("paxTransEsc3Arrivee"));
            datas.append("esc3FretKgD",getValues("fretKgEsc3Arrivee"));
            datas.append("esc3ExcBagD",getValues("excBagEsc3Arrivee"));
            datas.append("esc3FretTransD",getValues("fretTransEsc3Arrivee"));
            datas.append("esc3PosteD",getValues("postEsc3Arrivee"));
            
            datas.append("escale4FirD",esc4FirD);
            datas.append("esc4AdD",getValues("adEsc4Depart"));
            datas.append("esc4EnfD",getValues("enfEsc4Depart"));
            datas.append("esc4BebD",getValues("bebEsc4Depart"));
            datas.append("esc4PaxTransD",getValues("paxTransEsc4Depart"));
            datas.append("esc4FretKgD",getValues("fretKgEsc4Depart"));
            datas.append("esc4ExcBagD",getValues("excBagEsc4Depart"));
            datas.append("esc4FretTransD",getValues("fretTransEsc4Depart"));
            datas.append("esc4PosteD",getValues("postEsc4Depart"));

            fetch(api,{method:"POST", body: datas}).then(r=>r.json())
            .then(r=>{
                console.clear();
                Modal.success({
                    title:"Ajout mouvement",
                    content:"Mouvement bien enregistré",
                    okText:"Continuer",
                    onOk:()=>{
                        window.location.reload();
                    }
                });
            })
            .catch(e=>{
                console.clear();
                Modal.warning({
                    title:"Ajout mouvement",
                    content:`Une erreur s'est produite dans le système ${e}`
                })
                console.log(e);
            })
            //Depart

          },
        });
      } else {
        Modal.warning({
          title: "Erreur",
          content: errorDetail,
        });
      }
    };
    const checkTempsArrivee=()=>{
        if(moment(`${getValues('dateArrivee')} ${getValues('heureArrivee')}:00`).isValid()){
                                                                                    
            if(moment(`${getValues('dateArrivee')} ${getValues('heureArrivee')}:00`).isAfter(`${moment()}`))
            {
                notification['error']({
                    message:'Erreur saisie',
                    description:"Le moment d'arrivée n'est pas correctement saisi"
                });
                setValueHook("heureArrivee","");
            }else
            {
                checkBalisageArrivee();
            }
        }else
        {
            
        }
    }
    const checkBalisageArrivee=()=>{
        // setValueHook("balisageArrivee",true);
        let _dt = getValues("dateArrivee");
        let _heure = getValues("heureArrivee");

        let hasBalisage = true;
            coucheeLevees.forEach((chl, index) => {
            if (moment(`${_dt}`).format('YYYY-MM') == moment(`${chl['Mois']}`).format('YYYY-MM')) {
               setValueHook("leveeSoleil",chl["Q1L"]);
               setValueHook("coucheeSoleil",chl["Q1C"]);

                if (parseInt(moment(`${_dt}`).format('DD')) > 15) {
                    let range1 = moment(`${chl.Mois}T${chl.Q2L}`);
                    let range2 = moment(`${chl.Mois}T${chl.Q2C}`);
                    if (moment(`${chl.Mois}T${_heure}`).isBetween(range1, range2)) {
                        hasBalisage = false;
                        setValueHook("balisageArrivee",false);
                        //document.querySelector("#chkBalisage").removeAttribute("disabled");
                    } else {
                        hasBalisage = true;
                        setValueHook("balisageArrivee",true);
                        //document.querySelector("#chkBalisage").setAttribute("disabled", "disabled");
                    }
                } else {
                    let range1 = moment(`${chl.Mois}T${chl.Q1L}`);
                    let range2 = moment(`${chl.Mois}T${chl.Q1C}`);
                    if (moment(`${chl.Mois}T${_heure}`).isBetween(range1, range2)) {
                        hasBalisage = false;
                        setValueHook("balisageArrivee",false);
                        //document.querySelector("#chkBalisage").removeAttribute("disabled");
                    } else {
                        hasBalisage = true;
                        setValueHook("balisageArrivee",true);
                        //document.querySelector("#chkBalisage").setAttribute("disabled", "disabled");
                    }
                }
                //let quinzaine = moment(`${_dt}`).format('mm');
            }
        });
    }
    const checkBalisageDepart=()=>{
        // setValueHook("balisageArrivee",true);
        let _dt = getValues("dateDepart");
        let _heure = getValues("heureDepart");

        let hasBalisage = true;
        coucheeLevees.forEach((chl, index) => {
            if (moment(`${_dt}`).format('YYYY-MM') == moment(`${chl['Mois']}`).format('YYYY-MM')) {
                setValueHook("leveeSoleilD",chl["Q1L"]);
               setValueHook("coucheeSoleilD",chl["Q1C"]);
                if (parseInt(moment(`${_dt}`).format('DD')) > 15) {
                    let range1 = moment(`${chl.Mois}T${chl.Q2L}`);
                    let range2 = moment(`${chl.Mois}T${chl.Q2C}`);
                    if (moment(`${chl.Mois}T${_heure}`).isBetween(range1, range2)) {
                        hasBalisage = false;
                        setValueHook("balisageDepart",false);
                        //document.querySelector("#chkBalisage").removeAttribute("disabled");
                    } else {
                        hasBalisage = true;
                        setValueHook("balisageDepart",true);
                        //document.querySelector("#chkBalisage").setAttribute("disabled", "disabled");
                    }
                } else {
                    let range1 = moment(`${chl.Mois}T${chl.Q1L}`);
                    let range2 = moment(`${chl.Mois}T${chl.Q1C}`);
                    if (moment(`${chl.Mois}T${_heure}`).isBetween(range1, range2)) {
                        hasBalisage = false;
                        setValueHook("balisageDepart",false);
                        //document.querySelector("#chkBalisage").removeAttribute("disabled");
                    } else {
                        hasBalisage = true;
                        setValueHook("balisageDepart",true);
                        //document.querySelector("#chkBalisage").setAttribute("disabled", "disabled");
                    }
                }
                //let quinzaine = moment(`${_dt}`).format('mm');
            }
        });
    }

    const checkMomentDepart=()=>{
        if(moment(`${getValues('dateDepart')} ${getValues('heureDepart')}`).isValid()){
            if(moment(`${getValues('dateArrivee')} ${getValues('heureArrivee')}`).isValid()){
                if(moment(`${getValues('dateArrivee')} ${getValues('heureArrivee')}`).isAfter(`${getValues('dateDepart')} ${getValues('heureDepart')}`)){
                    notification["error"]({
                        message:"Erreur de saisie",
                        description:"La date depart ne peut pas être anterieure à la d'arrivée"
                    });
                    setValueHook("heureDepart","");
                }else
                 {
                    checkBalisageDepart();
                 }
            }
        }
    }

    const isMaxFret=(objet)=>  {
        let pmaEnter = parseInt(getValues("fretArrivee")) +
            parseInt(getValues("excBagArrivee")) +
            parseInt(getValues("fretTransArrivee")) + 
            parseInt(getValues("posteArrivee")) +
            (getValues("fretKgEsc1Arrivee") == null ? 0 : parseInt(getValues("fretKgEsc1Arrivee"))) +
            (getValues("excBagEsc1Arrivee") == null ? 0 : parseInt(getValues("excBagEsc1Arrivee"))); 
            // + esc1FretTrans.value == null ? 0 : parseInt(esc1FretTrans.value) + esc1Post.value == null ? 0 : parseInt(esc1Post.value) + parseInt(esc2Fret.value) + parseInt(esc2ExcBag.value) + parseInt(esc2FretTrans.value) + parseInt(esc2Post.value) + parseInt(esc3Fret.value) + parseInt(esc3ExcBag.value) + parseInt(esc3FretTrans.value) + parseInt(esc3Post.value) + parseInt(esc4Fret.value) + parseInt(esc4ExcBag.value) + parseInt(esc4FretTrans.value) + parseInt(esc4Post.value);
        //alert(esc1ExcBag.value == null ? 0 : parseInt(esc1ExcBag.value));
        if (pmaEnter > ((parseInt(getValues("pma") * 1000) * 90) / 100)) {
            // ev.preventDefault();
            //controlForm.isValid = false;
            notification["error"]({description:"Vous avez dépassé le poids maximum autorisé",message:"Depassement du poids"});
            setValueHook(objet,0);
        } else {
        }
    }
    /*const isMaxFretD = () =>  {
        let pmaEnterD = parseInt(formulaire.depart.fret.value) +
            parseInt(formulaire.depart.excBag.value) +
            parseInt(formulaire.depart.fretTrans.value) + parseInt(formulaire.depart.fretPost.value) +
            (formulaire.depart.esc1.fret.value == null ? 0 : parseInt(formulaire.depart.esc1.fret.value)) +
            (formulaire.depart.esc1.excBag.value == null ? 0 : parseInt(formulaire.depart.esc1.excBag.value)
            ); // + esc1FretTrans.value == null ? 0 : parseInt(esc1FretTrans.value) + esc1Post.value == null ? 0 : parseInt(esc1Post.value) + parseInt(esc2Fret.value) + parseInt(esc2ExcBag.value) + parseInt(esc2FretTrans.value) + parseInt(esc2Post.value) + parseInt(esc3Fret.value) + parseInt(esc3ExcBag.value) + parseInt(esc3FretTrans.value) + parseInt(esc3Post.value) + parseInt(esc4Fret.value) + parseInt(esc4ExcBag.value) + parseInt(esc4FretTrans.value) + parseInt(esc4Post.value);
        //alert(esc1ExcBag.value == null ? 0 : parseInt(esc1ExcBag.value));
        if (pmaEnterD > ((parseInt(aeronefCurrentPMA * 1000) * 90) / 100)) {
            // ev.preventDefault();
            //controlForm.isValid = false;
            app.ErrorAlert("Erreur : Vous avez dépassé le poids maximum autorisé");
            mouvement.controlCurrent.value = 0;
            mouvement.controlCurrent.focus();
        } else {
            mouvement.isValid = true;
        }
    }*/
   
    const tabulation=(event,nextControl,number=false,Ctrl="")=>{
        
        if(event.code==="Enter" || event.code=="NumpadEnter"){ 
            const c=document.getElementById(`${nextControl}`);
            const valInit=event.target.value;
            c.value="";
            if(number==true && document.getElementById(`${event.target.id}`).value=="")
            {
                document.getElementById(`${event.target.id}`).value=0; 

            }
            c.focus();
        }
        
    }
    const tabulationBySelect=(nextControl)=>{
        document.getElementById(`${nextControl}`).focus();
    }

    const [totalFretNA,setTotalFretNA]=useState(parseFloat(0));
    const [totalFretIA,setTotalFretIA]=useState(parseFloat(0));
    const calculTotalFretA=()=>{
        
        try
        {
            if(provenanceArrivee.substring(0,2)=="FZ")
            {
                    setValueHook("totFretNat",parseFloat(document.querySelector("#fretArrivee").value) ?? 0.00 + 
                    parseFloat(document.querySelector("#fretTransArrivee").value) ?? 0.00 +
                    parseFloat(document.querySelector("#postArrivee").value) ?? 0 +
                    parseFloat(document.querySelector("#paxFretEsc1Arr").value) ?? 0 +
                    parseFloat(document.querySelector("#paxFretTransEsc1Arr").value) ?? 0 +
                    parseFloat(document.querySelector("#paxPostEsc1Arr").value) ?? 0
                );
            }else
            {
                setValueHook("totFretInt",parseFloat(document.querySelector("#fretArrivee").value) ?? 0.00 + 
                    parseFloat(document.querySelector("#fretTransArrivee").value) ?? 0.00 +
                    parseFloat(document.querySelector("#postArrivee").value) ?? 0 +
                    parseFloat(document.querySelector("#paxFretEsc1Arr").value) ?? 0 +
                    parseFloat(document.querySelector("#paxFretTransEsc1Arr").value) ?? 0 +
                    parseFloat(document.querySelector("#paxPostEsc1Arr").value) ?? 0
                );
            }
        }catch(ex)
        {
           
            notification["error"]({
                message:"Erreur",
                description:ex
            });
        }
    };
    useEffect(()=>{

    });
    const handleReset=(e)=>{
        if(window.confirm("Voulez-vous vraiment réinitialiser le formulaire ?")===true)
        {

        }else
        {
            e.preventDefault();
        }
        /*Modal.confirm({
            title:"Reset Formulaire",
            content:"Voulez-vous vraiment réinitialiser le formulaire ?",
            okText:"OUI",
            cancelText:"Annuler",
            onOk:()=>{

            },
            onCancel:()=>e.preventDefault()
        });*/
    }
    const [windowOpened,setWindowOpened]=useState(true);
    const navigate=useNavigate();
    return (
        <>
        <Snackbar 
            anchorOrigin={{ vertical, horizontal }}
            open={toastIsOpened}
            onClose={closeToast}
            autoHideDuration={5000}
            transitionDuration={1000}
            key={1}>
            <Alert severity={state.status} sx={{ width: '100%' }} elevation={6} variant="filled">
                {state.title}
            </Alert>
        </Snackbar>
            {
                windowOpened && (
                <div>
                    <div className='flex justify-center pt-2'>
                        
                        <div className='pt-2'>
                            <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
                                <Paper>
                                    <div className="text-right justify-end pr-3">
                                        <button type="button" onClick={()=>{navigate("/home") }} className="text-14 font-bold hover:bg-blue-400 p-1 hover:text-white hover:p-1 hover:shadow-sm">x</button>
                                    </div>
                                    <div className='h-2'>&nbsp; </div>
                                    <Box sx={{ width: '100%' }}>
                                        <div className=" rounded-sm m-2 mt-1 p-2 pt-2 bg-gray-500 mt-2">
                                            <table className="w-small" width="700" height="" border="0">
                                                <tbody>
                                                    <tr>
                                                        <td className="w-28 text-12 text-right pr-2">Numero FT : </td>
                                                        <td className='w-44'>
                                                            <div className='flex justify-between space-x-2 align-middle'>
                                                                <input 
                                                                    name="numFt"
                                                                    tabIndex="1" {...register("numFt")} 
                                                                    autoComplete="off"
                                                                    onKeyUp={
                                                                        (e)=>{
                                                                                setNumFtLong(getValues("numFt"));
                                                                                tabulation(e,"immatriculation");
                                                                            }
                                                                    } 
                                                                    onChange={(e)=>{onChangeNumFt(e)}}
                                                                    defaultValue="" 
                                                                    type="text" 
                                                                    className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' />
                                                                <div className='w-24 text-right text-10'>{numFtLong}</div>
                                                            </div>
                                                        </td>
                                                        <td className='w-30 items-end'>
                                                            <div className='items-end text-12 w-full text-right pr-2'> Type : </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" {...register("typeAero")} disabled="true" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-12 text-right pr-2">Immatriculation : </td>
                                                        <td className=''>
                                                            {/* <input type="text" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' /> */}
                                                            <Select tabIndex="2" 
                                                                    id='immatriculation'
                                                            
                                                                onChange={selectImmatriculation}
                                                                className='w-full min-w-full text-sm'
                                                                allowClear={true}
                                                                size="small"
                                                                showSearch
                                                                placeholder="Selectionner immatriculation"
                                                                optionFilterProp="children"
                                                                
                                                                onSearch={onSearch}>
                                                                {
                                                                    immatriculations && (immatriculations?.map(i => {

                                                                        return (<Option key={i.IdImm} value={i.IdImm}>{i.Immatriculation}</Option>)
                                                                    }))
                                                                }
                                                            </Select>
                                                            
                                                            {errors.immatriculation&&<span className='text-red'>{errors.immatriculation.message}</span>}
                                                        </td>
                                                        <td className='items-end'>
                                                            <div className='text-right items-end text-12 w-full pr-2'> PMA : </div>
                                                        </td>
                                                        <td>
                                                            <input type="text" {...register("pma")} disabled="true" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td className="text-12 text-right pr-2">Exploitant : </td>
                                                        <td className=''>
                                                            <Select tabindex="3"
                                                                id="exploitant"
                                                                value={exploitantValue}
                                                                className='w-full min-w-full'
                                                                {...register("exploitant")}
                                                                onKeyUp={(e)=>tabulation(e,"dateArrivee")}
                                                                allowClear={true}
                                                                size="small"
                                                                showSearch
                                                                placeholder="Exploitant..."
                                                                optionFilterProp="children"
                                                                onChange={onChange}
                                                                onSearch={onSearch}>
                                                                <Option value=""></Option>
                                                                {clients?.map(i => {

                                                                    return (<Option key={i.IdCl} value={i.IdCl}>{i.NomCl}</Option>)
                                                                })}
                                                            </Select>
                                                        </td>
                                                        <td className='items-end'>
                                                            <div className='text-right items-end text-12 w-full pr-2'> Proprietaire : </div>
                                                        </td>
                                                        <td>
                                                            <input {...register("proprietaire")} disabled="true" type="text" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' />
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                                <Tab label="ARRIVEE"  {...a11yProps(0)} icon={<FlightLandIcon />} iconPosition="start" />
                                                {
                                                    tabDepart===true && 
                                                    <Tab label="DEPART" disabled={false} icon={<FlightTakeoffIcon />} iconPosition="start"  {...a11yProps(1)} />
                                                }
                                            </Tabs>
                                        </Box>
                                        <TabPanel value={value} index={0}>

                                            <div className='w-800 border rounded-sm bg-gray-50 p-2 space-x-2'>
                                                <div className='flex'>
                                                    <div className='w-auto border pr-2'>
                                                        <table className="-ml-5">
                                                            <tbody>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span >Date : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <input type="date" 
                                                                            id="dateArrivee"
                                                                            {...register("dateArrivee",{required:true})} 
                                                                            onBlur={(e)=>
                                                                                {
                                                                                    if(moment(e.target.value).isAfter(`${moment().format("YYYY-MM-DD")}`)){                                                                            
                                                                                        notification['error']({
                                                                                            message:'Erreur saisie',
                                                                                            description:"La date ne peut pas être > à la date du jour"
                                                                                        })
                                                                                        setValueHook("dateArrivee","");
                                                                                    }else{
                                                                                    checkTempsArrivee()
                                                                                        // if(moment(`${getValues('heureArrivee')}`))
                                                                                    }
                                                                                }
                                                                            } 
                                                                            onKeyUp={(e)=>{tabulation(e,"heureArrivee")}}
                                                                        tabIndex="4" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Fret : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input tabIndex="13" 
                                                                            id="fretArrivee"
                                                                        {...register("fretArrivee",{required:true})}  
                                                                        onBlur={(e)=>{isMaxFret("fretArrivee")}}
                                                                        defaultValue="0" 
                                                                        type="number" 
                                                                        className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                        onKeyUp={(e)=>{tabulation(e,"excBagArrivee",true,"fretArrivee"); calculTotalFretA()}}
                                                                    />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Heure : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <input tabIndex="5"
                                                                            id='heureArrivee'
                                                                            {...register("heureArrivee",{required:true})} 
                                                                            onBlur={(e)=>checkTempsArrivee()} 
                                                                            type="time" 
                                                                            className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' 
                                                                            onKeyUp={(e)=>{tabulation(e,"flArrivee",true)}}
                                                                        />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Excedent Bagage : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input
                                                                            id="excBagArrivee"
                                                                            tabIndex="14" {...register("excBagArrivee",{required:true,valueAsNumber:true})} 
                                                                            defaultValue={0} type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretTransArrivee",true,"excBagArrivee")}}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span >FL : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <input tabIndex="6"  
                                                                            id="flArrivee"                                                                        
                                                                            type="number" 
                                                                            {...register("flArrivee",{required:true,min:30,max:600})} 
                                                                            onBlur={(e)=>{
                                                                                if(getValues("flArrivee")>600 || getValues("flArrivee")<30)
                                                                                {
                                                                                    e.preventDefault();
                                                                                    notification["error"]({
                                                                                        message:"Erreur de saisie",
                                                                                        description:"Le niveau de vol doit être compris entre 30 et 600"
                                                                                    });
                                                                                    setValueHook("flArrivee",30);
                                                                                }
                                                                            }} 
                                                                            className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' 
                                                                            onKeyUp={(e)=>{tabulation(e,"provenance")}}
                                                                        />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Fret Trans : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input 
                                                                            id="fretTransArrivee"
                                                                            tabIndex={14} 
                                                                            {...register("fretTransArrivee",{required:true,valueAsNumber:true})} 
                                                                            onChange={(e)=>{calculTotalFretA()}}
                                                                            onBlur={(e)=>{isMaxFret("fretTransArrivee")}}
                                                                            defaultValue="0" type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"postArrivee",true,"fretTransArrivee")}}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Provenance : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <Select
                                                                        tabIndex="7"
                                                                        id="provenance"
                                                                        {...register("provenance")}
                                                                            className='w-full min-w-full'
                                                                            
                                                                            size='small'
                                                                            showSearch
                                                                            allowClear={true}
                                                                            placeholder="Provenance"
                                                                            optionFilterProp="children"
                                                                            onChange={selectProvenanceArrivee}
                                                                            onSearch={onSearch}>
                                                                            {villes?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}--[{i.LibelleFir}]</Option>)
                                                                            })}
                                                                        </Select>
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Poste : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input tabIndex={15} 
                                                                            id="postArrivee"
                                                                            {...register("posteArrivee",{required:true})} 
                                                                            onBlur={(e)=>{isMaxFret("postArrivee")}}
                                                                            onChange={()=>calculTotalFretA()}
                                                                            defaultValue="0" 
                                                                            type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"piloteArrivee",true,"postArrivee")}}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span></span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <input disabled="true" type="text" {...register("provenanceDetailArrivee")} className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Nom pilote : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input 
                                                                            id="piloteArrivee"
                                                                            tabIndex={16} 
                                                                            {...register("piloteArrivee")} 
                                                                            onChange={(e)=>{
                                                                                            setValueHook("piloteDepart",e.target.value); 
                                                                                            setValueHook("piloteArrivee",e.target.value.toUpperCase())
                                                                                            }}
                                                                            defaultValue="" type="text" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"categVolArrivee")}}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Pt Entrée : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <Select
                                                                        id="ptEntree"
                                                                        disabled={ptEntIsDisabled}
                                                                        tabIndex="8"
                                                                        {...register("ptEntree",{required:true})}
                                                                        defaultValue=""
                                                                            className='w-full min-w-full'
                                                                            allowClear={true}
                                                                            size="small"
                                                                            showSearch
                                                                            placeholder=""
                                                                            optionFilterProp="children"
                                                                            onChange={selectPtEntree}
                                                                            onSearch={onSearch}>
                                                                            <Option value="0"></Option>
                                                                            {firs?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                                            })}
                                                                        </Select>
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Categ Vol : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <Select
                                                                            id="categVolArrivee"
                                                                            className='w-full min-w-full'
                                                                            {...register("categVolArrivee",{required:true})}
                                                                            size='small'
                                                                            tabIndex={17}
                                                                            showSearch
                                                                            defaultValue={{ value: 'COM', label: 'COMM' }}
                                                                            onChange={(e)=>{setCategVolArrivee(e)}}
                                                                            onSearch={onSearch}
                                                                            loading={false}
                                                                        >
                                                                            <Option value="COM">COMM</Option>
                                                                            <Option value="HUM">HUM</Option>
                                                                            <Option value="PRIV">PRIV</Option>
                                                                            <Option value="MLN">MLN</Option>
                                                                            <Option value="MLE">MLE</Option>
                                                                            <Option value="ETAT">ETAT</Option>
                                                                            <Option value="DIPL">DIPL</Option>
                                                                        </Select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Passagers :</span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <div className="flex">
                                                                            <div className="w-1/3 text-10 text-center">AD :</div>
                                                                            <div className="w-1/3 text-10 text-center">Enf :</div>
                                                                            <div className="w-1/3 text-10 text-center">Béb :</div>
                                                                        </div>
                                                                        <div className="flex">
                                                                            <div className="w-1/3 text-10">
                                                                                <input 
                                                                                    tabIndex="9"
                                                                                    id="paxAdArrivee" 
                                                                                    {...register("paxAdArrivee")} 
                                                                                    defaultValue="0" type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-14' 
                                                                                    onKeyUp={(e)=>{tabulation(e,"paxEnfArrivee",true,"paxAdArrivee")}}
                                                                                />
                                                                            </div>
                                                                            <div className="w-1/3 text-10">
                                                                                <input  
                                                                                    id="paxEnfArrivee"
                                                                                    tabIndex="10" 
                                                                                    {...register("paxEnfArrivee")} 
                                                                                    defaultValue="0" 
                                                                                    type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-14' 
                                                                                    onKeyUp={(e)=>{tabulation(e,"paxBebArrivee",true,"paxEnfArrivee")}}    
                                                                                />
                                                                            </div>
                                                                            <div className="w-1/3 text-10">
                                                                                <input
                                                                                    id="paxBebArrivee"  
                                                                                    tabIndex="11" 
                                                                                    {...register("paxBebArrivee")} 
                                                                                    defaultValue="0" type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-14' 
                                                                                    onKeyUp={(e)=>{tabulation(e,"paxTransArrivee",true,"paxBebArrivee")}}    
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className='w-40 items-end'>

                                                                    </td>
                                                                    <td className='w-auto'>

                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Pax Transit : </span>
                                                                    </td>
                                                                    <td className="">
                                                                        <div className='flex'>
                                                                            <input 
                                                                                id="paxTransArrivee"
                                                                                tabIndex="12" 
                                                                                {...register("paxTransArrivee")} 
                                                                                defaultValue="0" type="number" 
                                                                                className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-1/3' 
                                                                                onKeyUp={(e)=>{tabulation(e,"paxCorrArrivee",true,"paxTransArrivee")}}
                                                                            />
                                                                            <span className='text-[11px]'>Corresp</span>
                                                                            <input 
                                                                                id="paxCorrArrivee"
                                                                                tabIndex="13" 
                                                                                {...register("paxCorrArrivee")} 
                                                                                defaultValue="0" type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-1/3' 
                                                                                onKeyUp={(e)=>{tabulation(e,"fretArrivee",true,"paxCorrArrivee")}}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    
                                                                    <td className='w-auto items-end pl-14' colSpan="3">
                                                                        <div className='flex space-x-7'>
                                                                            <div className='items-end text-12 text-right pr-2 flex'>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input tabIndex={18} className='mr-1' 
                                                                                        {...register("chkAjoutEsc")}
                                                                                        onChange={(e) => 
                                                                                            { 
                                                                                                if(e.target.checked){
                                                                                                    if(provenanceArrivee=="")
                                                                                                    {
                                                                                                        notification["error"]({
                                                                                                            message:"Erreur",
                                                                                                            description:"Veuillez selectionner d'abord la provenance du vol"
                                                                                                        });
                                                                                                        e.preventDefault();
                                                                                                        e.target.checked=false;
                                                                                                    }else
                                                                                                    {
                                                                                                        setShowEscalesContainer(true);
                                                                                                    }
                                                                                                }else
                                                                                                {
                                                                                                    setShowEscalesContainer(false); } 
                                                                                                } 
                                                                                            }
                                                                                                type="checkbox" />
                                                                                        Ajout escales
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='items-end text-12 text-right pr-2 flex'>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label">
                                                                                        <input tabIndex={19} type="checkbox" className="mr-1" {...register("balisageArrivee")} />
                                                                                        Balisage
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='w-20 align-middle m-auto'>
                                                        <div>
                                                            <div className='text-10 mb-3 items-center text-center'>
                                                                Total fret:
                                                            </div>
                                                            <div>
                                                                <div className='flex w-40'>
                                                                    <div className='text-10 w-12 text-10'>
                                                                        Nat
                                                                    </div>
                                                                    <div className='flex w-16'>
                                                                        <input type="number" value={totalFretNA} {...register("totFretNat")} className='border rounded-sm h-5 w-full italic text-sm p-1' defaultValue="0" disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='flex w-40'>
                                                                    <div className='text-10 w-12 text-10'>
                                                                        Int
                                                                    </div>
                                                                    <div className='flex w-16'>
                                                                        <input type="text" value={totalFretIA} {...register("totFretInt")} className='border rounded-sm h-5 w-full italic text-sm p-1' defaultValue="0" disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {showEscalesContainer && (
                                            <div style={{  }} className='w-800 border rounded-sm bg-gray-50 pt-2 space-x-2 mt-3'>
                                                
                                                <div style={{  }} className='flex pb-3 space-x-1 pr-1 pl-1'>
                                                    <div className="w-7 text-10"> 1 :</div>
                                                    <div className="w-28 text-10">
                                                        Escales : 
                                                        <Select
                                                            {...register("firEsc1Arrivee")}
                                                            className='w-full min-w-7'
                                                            allowClear={true}
                                                            size="small"
                                                            showSearch
                                                            placeholder="Escale..."
                                                            optionFilterProp="children"
                                                            onSearch={onSearch}
                                                            onChange={(e)=>{
                                                                setEsc1FirA(e);
                                                                setVilles2(villes1.filter(v=>{return v.IdFir!=e}));
                                                                e!=="" && document.querySelector("#paxAdEsc1Arr").focus();
                                                            }}
                                                            onKeyUp={(e)=>{tabulation(e,"#paxAdEsc1Arr")}}
                                                        >
                                                            {villes1?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div className="w-24 text-10"> 
                                                        Adultes :
                                                        <input id="paxAdEsc1Arr" type="number" {...register("adEsc1Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxEnfEsc1Arr",true,"paxAdEsc1Arr")}}
                                                        /> </div>
                                                    <div className="w-24 text-10"> 
                                                        Enfants :
                                                        <input id="paxEnfEsc1Arr" type="number" {...register("enfEsc1Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxBebEsc1Arr",true,"paxEnfEsc1Arr")}}
                                                        /> </div>
                                                    <div className="w-24 text-10"> 
                                                        Bébes :
                                                        <input id="paxBebEsc1Arr" type="number" {...register("bebEsc1Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxPaxTransEsc1Arr",true,"paxBebEsc1Arr")}}
                                                        /> </div>
                                                    <div className="w-24 text-10"> 
                                                        Pax Trans : 
                                                        <input id="paxPaxTransEsc1Arr" type="number" {...register("paxTransEsc1Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxFretEsc1Arr",true,"paxPaxTransEsc1Arr")}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> 
                                                        Fret Kg : 
                                                        <input id="paxFretEsc1Arr" type="number" {...register("fretKgEsc1Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxExcBagEsc1Arr",true,"paxFretEsc1Arr")}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> 
                                                        Exc Bag :
                                                        <input id="paxExcBagEsc1Arr" type="number" {...register("excBagEsc1Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxFretTransEsc1Arr",true,"paxExcBagEsc1Arr")}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> 
                                                        Fret Trans : 
                                                        <input id="paxFretTransEsc1Arr" type="number" {...register("fretTransEsc1Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxPostEsc1Arr",true,"paxFretTransEsc1Arr")}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> 
                                                        Post : 
                                                        <input id="paxPostEsc1Arr" type="number" {...register("postEsc1Arrivee")} onChange={(e)=>{calculTotalFretA()}} defaultValue="0" className='w-full border px-1 h-6' /> </div>
                                                    <div className="w-10 text-10"> 
                                                        &nbsp;
                                                        <button type="button" onClick={(e) => 
                                                            { 
                                                                
                                                                esc1FirA!==""? setArriveeEsc2Container(true):
                                                                    notification['error']({
                                                                        message:'Erreur',
                                                                        description:"Veuillez selectionner l'escale"
                                                                    });
                                                            }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>+</button></div>
                                                    <div className="w-10 text-10"> 
                                                        &nbsp;
                                                        <button type='button' 
                                                            onClick={() => { 
                                                                setShowEscalesContainer(false);
                                                                setArriveeEsc2Container(false); 
                                                                setArriveeEsc3Container(false); 
                                                                setArriveeEsc4Container(false); 
                                                                setValueHook("chkAjoutEsc",false);
                                                                setShowDepartEscalesContainer(false); 
                                                                document.querySelector("#chkAjoutEscDep").checked=false;
                                                                setEsc1FirA("");
                                                                setValueHook("adEsc1Arrivee","");
                                                                setValueHook("enfEsc1Arrivee",0);
                                                                setValueHook("enfEsc1Depart","0");
                                                                setValueHook("bebEsc1Arrivee","0");
                                                                setValueHook("paxTransEsc1Arrivee","0");
                                                                setValueHook("fretKgEsc1Arrivee","0");
                                                                setValueHook("excBagEsc1Arrivee","0");
                                                                setValueHook("fretTransEsc1Arrivee","0");
                                                                setValueHook("postEsc1Arrivee","0");
                                                            }} 
                                                            className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button></div>
                                                </div>
                                                {arriveeEsc2Container && (
                                                <div className='flex pb-3 space-x-1 pr-1'>
                                                    <div className="w-7 text-10">2 :</div>
                                                    <div className="w-28 text-10">
                                                        <Select
                                                            id="firEsc2Arr"
                                                            {...register("firEsc2Arrivee")}
                                                            className='w-full min-w-7'
                                                            allowClear={true}
                                                            size="small"
                                                            showSearch
                                                            placeholder="Escale..."
                                                            optionFilterProp="children"
                                                            onSearch={onSearch}
                                                            onKeyUp={(e)=>{tabulation(e,"paxAdEsc2Arr")}}
                                                            onChange={(e)=>{
                                                            setEsc2FirA(e);
                                                            setVilles3(villes2.filter(v=>{return v.IdFir!=e}));
                                                            e!=="" && document.querySelector("#paxAdEsc2Arr").focus();
                                                        }}>
                                                        {villes2?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                            return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                        })}
                                                        </Select>
                                                    </div>
                                                    <div className="w-24 text-10">
                                                        <input id="paxAdEsc2Arr" type="number" {...register("adEsc2Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxEnfEsc2Arr",true)}}
                                                        />
                                                    </div>
                                                    <div className="w-24 text-10"> 
                                                        <input id="paxEnfEsc2Arr" type="number" {...register("enfEsc2Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxBebEsc2Arr",true)}}
                                                        /> 
                                                        </div>
                                                    <div className="w-24 text-10"> 
                                                        <input id="paxBebEsc2Arr" type="number" {...register("bebEsc2Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxTransEsc2Arr",true)}}
                                                        /> 
                                                        </div>
                                                    <div className="w-24 text-10"> 
                                                        <input id="paxTransEsc2Arr" type="number" {...register("paxTransEsc2Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"fretEsc2Arr",true)}}
                                                        />  
                                                        </div>
                                                    <div className="w-24 text-10"> 
                                                        <input id="fretEsc2Arr" type="number" {...register("fretKgEsc2Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"excBagEsc2Arr",true)}}
                                                        />  
                                                        </div>
                                                    <div className="w-24 text-10"> 
                                                        <input id="excBagEsc2Arr" type="number" {...register("excBagEsc2Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"fretTransEsc2Arr",true)}}
                                                        />  
                                                        </div>
                                                    <div className="w-24 text-10"> 
                                                        <input id="fretTransEsc2Arr" type="number" {...register("fretTransEsc2Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"postEsc2Arr",true)}}
                                                        />  
                                                        </div>
                                                    <div className="w-24 text-10"> 
                                                        <input id="postEsc2Arr" type="number" {...register("postEsc2Arrivee",true)} defaultValue="0" className='w-full border px-1 h-6' 
                                                            
                                                        /> 
                                                        </div>
                                                    <div className="w-10 text-10"> 
                                                        <button onClick={() => { 
                                                            esc2FirA!==""? setArriveeEsc3Container(true):
                                                            notification['error']({
                                                                message:'Erreur',
                                                                description:"Veuillez selectionner l'escale"
                                                            });
                                                        }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>+</button>
                                                    </div>
                                                    <div className="w-10 text-10"> 
                                                        <button type="button" onClick={() => 
                                                            { 
                                                                setArriveeEsc4Container(false); 
                                                                setArriveeEsc3Container(false); 
                                                                setArriveeEsc2Container(false); 
                                                                setEsc2FirA("");
                                                                setValueHook("adEsc2Arrivee","");
                                                                setValueHook("enfEsc2Arrivee",0);
                                                                setValueHook("enfEsc2Depart","0");
                                                                setValueHook("bebEsc2Arrivee","0");
                                                                setValueHook("paxTransEsc2Arrivee","0");
                                                                setValueHook("fretKgEsc2Arrivee","0");
                                                                setValueHook("excBagEsc2Arrivee","0");
                                                                setValueHook("fretTransEsc2Arrivee","0");
                                                                setValueHook("postEsc2Arrivee","0");
                                                            }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button>
                                                    </div>
                                                </div>
                                                )}
                                                {arriveeEsc3Container && (
                                                <div style={{  }} className='flex pb-3 space-x-1 pr-1'>
                                                    <div className="w-7 text-10"> 3 :</div>
                                                    <div className="w-28 text-10">
                                                        <Select
                                                            className='w-full min-w-7'
                                                            allowClear={true}
                                                            size="small"
                                                            showSearch
                                                            placeholder="Escale..."
                                                            optionFilterProp="children"
                                                            
                                                            onSearch={onSearch}
                                                            onChange={(e)=>{
                                                                setEsc3FirA(e);
                                                                setVilles4(villes3.filter(v=>{return v.IdFir!=e}));
                                                            }}
                                                        >
                                                            {villes3?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div className="w-24 text-10"> <input id="paxAdEsc3Arr" type="number" {...register("adEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                        onKeyUp={(e)=>{tabulation(e,"paxEnfEsc3Arr",true)}}
                                                        /> </div>
                                                    <div className="w-24 text-10"> <input id="paxEnfEsc3Arr" type="number" {...register("enfEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                        onKeyUp={(e)=>{tabulation(e,"paxBebEsc3Arr",true)}}
                                                        /> </div>
                                                    <div className="w-24 text-10"> <input id="paxBebEsc3Arr" type="number" {...register("bebEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                        onKeyUp={(e)=>{tabulation(e,"paxTransesc3Arr",true)}}
                                                        /> </div>
                                                    <div className="w-24 text-10"> <input id="paxTransesc3Arr" type="number" {...register("paxTransEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                        onKeyUp={(e)=>{tabulation(e,"fretEsc3Arr",true)}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> <input id="fretEsc3Arr" type="number" {...register("fretKgEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                        onKeyUp={(e)=>{tabulation(e,"excBagesc3Arr",true)}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> <input id="excBagesc3Arr" type="number" {...register("excBagEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                        onKeyUp={(e)=>{tabulation(e,"fretTransEsc3Arr",true)}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> <input id="fretTransEsc3Arr" type="number" {...register("fretTransEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                        onKeyUp={(e)=>{tabulation(e,"postEsc3Arr",true)}}
                                                        />  </div>
                                                    <div className="w-24 text-10"> <input id="postEsc3Arr" type="number" {...register("postEsc3Arrivee")} defaultValue="0" className='w-full border px-1 h-6' /> </div>
                                                    <div className="w-10 text-10"> 
                                                        <button onClick={() => { 
                                                            esc3FirA!==""? setArriveeEsc4Container(true):
                                                            notification['error']({
                                                                message:'Erreur',
                                                                description:"Veuillez selectionner l'escale"
                                                            });
                                                        }} 
                                                            className='w-8 bg-blue-500 h-6 rounded-sm text-white'>+</button>
                                                    </div>
                                                    <div className="w-10 text-10"> 
                                                        <button type="button" onClick={() => { 
                                                            setArriveeEsc4Container(false);
                                                            setArriveeEsc3Container(false);
                                                            setEsc3FirA("");
                                                            setValueHook("adEsc3Arrivee","");
                                                            setValueHook("enfEsc3Arrivee",0);
                                                            setValueHook("enfEsc3Depart","0");
                                                            setValueHook("bebEsc3Arrivee","0");
                                                            setValueHook("paxTransEsc3Arrivee","0");
                                                            setValueHook("fretKgEsc3Arrivee","0");
                                                            setValueHook("excBagEsc3Arrivee","0");
                                                            setValueHook("fretTransEsc3Arrivee","0");
                                                            setValueHook("postEsc3Arrivee","0");
                                                        }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button>
                                                    </div>
                                                </div>
                                                )}
                                                {arriveeEsc4Container && (
                                                <div style={{  }} className='flex pb-3 space-x-1 pr-1'>
                                                    <div className="w-7 text-10"> 4 :</div>
                                                    <div className="w-28 text-10">
                                                        <Select
                                                            className='w-full min-w-full text-xs'
                                                            allowClear={true}
                                                            size="small"
                                                            showSearch
                                                            placeholder="Escale..."
                                                            optionFilterProp="children"
                                                            onSearch={onSearch}
                                                            onChange={e=>setEsc4FirA(e)}
                                                        >
                                                            {villes4?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                            })}
                                                        </Select>
                                                    </div>
                                                    <div className="w-24 text-10"> <input id="paxAdEsc4Arr" type="number" {...register("adEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxEnfEsc4Arr",true)}}
                                                    /> </div>
                                                    <div className="w-24 text-10"> <input id="paxEnfEsc4Arr" type="number" {...register("enfEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxBebEsc4Arr",true)}}
                                                    /> </div>
                                                    <div className="w-24 text-10"> <input id="paxBebEsc4Arr" type="number" {...register("bebEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"paxTransEsc4Arr",true)}}
                                                    /> </div>
                                                    <div className="w-24 text-10"> <input id="paxTransEsc4Arr" type="number" {...register("paxTransEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"fretEsc4Arr",true)}}
                                                    />  </div>
                                                    <div className="w-24 text-10"> <input id="fretEsc4Arr" type="number" {...register("fretKgEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"excBagEsc4Arr",true)}}
                                                    />  </div>
                                                    <div className="w-24 text-10"> <input id="excBagEsc4Arr" type="number" {...register("excBagEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"fretTransEsc4Arr",true)}}
                                                    />  </div>
                                                    <div className="w-24 text-10"> <input id="fretTransEsc4Arr" type="number" {...register("fretTransEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                            onKeyUp={(e)=>{tabulation(e,"postEsc4Arr",true)}}
                                                    />  </div>
                                                    <div className="w-24 text-10"> <input id="postEsc4Arr" type="number" {...register("postEsc4Arrivee")} defaultValue="0" className='w-full border px-1 h-6' 
                                                    /> </div>
                                                    <div className="w-10 text-10"> <button type="button" onClick={() => { 
                                                        setArriveeEsc4Container(false);
                                                        setEsc4FirA("");
                                                        setValueHook("adEsc4Arrivee","");
                                                        setValueHook("enfEsc4Arrivee",0);
                                                        setValueHook("enfEsc4Depart","0");
                                                        setValueHook("bebEsc4Arrivee","0");
                                                        setValueHook("paxTransEsc4Arrivee","0");
                                                        setValueHook("fretKgEsc4Arrivee","0");
                                                        setValueHook("excBagEsc4Arrivee","0");
                                                        setValueHook("fretTransEsc4Arrivee","0");
                                                        setValueHook("postEsc4Arrivee","0"); 
                                                    }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button></div>
                                                    <div className="w-10 text-10"> &nbsp;</div>
                                                </div>
                                                )}
                                            </div>
                                            )}
                                            <div className='w-36 mt-2 space-x-2 border rounded-sm pr-1 pb-1'>
                                                <div className='flex'>
                                                    <div className='w-1/2 text-sm pl-4'>Levée :</div>
                                                    <div className='w-1/2 text-sm pl-4'>Couchée: </div>
                                                </div>
                                                <div className='flex space-x-1'>
                                                    <div className='w-1/2'><input type="text" {...register("leveeSoleil")} className='h-[25px] w-full border text-[11px] font-bold' disabled={true} /></div>
                                                    <div className='w-1/2'><input type="text" {...register("coucheeSoleil")} className='h-[25px] w-full border text-[11px] font-bold' disabled={true} /></div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={1}>
                                            <div className='w-800 border rounded-sm bg-gray-50 p-2 space-x-2'>
                                                <div className='flex'>
                                                    <div className='w-auto border pr-2'>
                                                        <table className="-ml-5">
                                                            <tbody>
                                                            <tr>
                                                                <td colSpan={2} className={wrapperLabel+" text-left justify-start"}>
                                                                    <span className='text-10 text-left mb-3 justify-start bg-gray-600 rounded-md px-5 py-1 text-white mb-4'>Arrivée : {moment(`"${getValues("dateArrivee")}"`).format("DD/MM/YYYY")} à {getValues("heureArrivee")}</span>
                                                                </td>
                                                                <td colSpan={2} className={wrapperLabel+" text-left justify-start"}>
                                                                    
                                                                </td>
                                                                
                                                            </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span >Date : </span>
                                                                    </td>
                                                                    <td className="w-48">
                                                                        <input 
                                                                            tabIndex={20}
                                                                            {...register("dateDepart",{required:true})} 
                                                                            type="date" 
                                                                            onBlur={(e)=>{
                                                                                checkMomentDepart()
                                                                            }}
                                                                            className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' 
                                                                            onKeyUp={(e)=>{tabulation(e,"heureDep")}}
                                                                        />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Fret : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input 
                                                                            id="fretDep"
                                                                            tabIndex={33} 
                                                                            {...register("fretDepart")}
                                                                            defaultValue="0" 
                                                                            type="number" className='w-full border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm'
                                                                            onKeyUp={(e)=>{tabulation(e,"excBagDep",true)}}
                                                                            />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Heure : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <input 
                                                                            id="heureDep"
                                                                            tabIndex="21" 
                                                                            {...register("heureDepart")}
                                                                            type="time"
                                                                            onBlur={(e)=>{
                                                                                checkMomentDepart()
                                                                            }}
                                                                            className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' 
                                                                            onKeyUp={(e)=>{tabulation(e,"flDep")}}
                                                                            />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Excedent Bagage : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input 
                                                                            id="excBagDep"
                                                                            tabIndex={34} 
                                                                            {...register("excBagDepart",{required:true,valueAsNumber:true})}
                                                                            type="number" 
                                                                            defaultValue="0"
                                                                            className='w-full border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretTransDep",true)}}    
                                                                        />
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span >FL : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <input 
                                                                            id="flDep"
                                                                            tabIndex={22} 
                                                                            {...register("flDepart",{required:true,min:30,max:600})} 
                                                                            onBlur={(e)=>{
                                                                                if(getValues("flDepart")>600 || getValues("flDepart")<30)
                                                                                {
                                                                                    e.preventDefault();
                                                                                    notification["error"]({
                                                                                        message:"Erreur de saisie",
                                                                                        description:"Le niveau de vol doit être compris entre 30 et 600"
                                                                                    });
                                                                                    setValueHook("flDepart",30);
                                                                                }
                                                                            }} 
                                                                            type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' 
                                                                            onKeyUp={(e)=>{tabulation(e,"destination")}}
                                                                        />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Fret Trans : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input 
                                                                            id="fretTransDep"
                                                                            tabIndex={35}
                                                                            {...register("fretTransDepart",{required:true,valueAsNumber:true})} 
                                                                            type="number"
                                                                            defaultValue="0" 
                                                                            className='w-full border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"postDep",true)}}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Destination : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <Select
                                                                            id="destination"
                                                                        tabIndex={23}
                                                                            {...register("destination",{required:true})}
                                                                            className='w-full min-w-full'
                                                                            size='small'
                                                                            showSearch
                                                                            allowClear={true}
                                                                            placeholder="Destination"
                                                                            optionFilterProp="children"
                                                                            onChange={selectProvenanceDepart}
                                                                            onSearch={onSearch}>
                                                                            {villesD?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}--[{i.LibelleFir}]</Option>)
                                                                            })}
                                                                        </Select>
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Poste : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input 
                                                                            id="postDep"
                                                                            tabIndex={36} 
                                                                            {...register("posteDepart",{required:true})}
                                                                            defaultValue="0"
                                                                            type="number" 
                                                                            className='w-full border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"piloteDep",true)}}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span></span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                    <input 
                                                                    disabled="true" 
                                                                    type="text" 
                                                                    {...register("provenanceDetailDepart")} className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-full' />
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Nom pilote : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <input 
                                                                            id="piloteDep"
                                                                            tabIndex={37} 
                                                                            {...register("piloteDepart")}
                                                                            onChange={(e)=>{setValueHook("piloteDepart",e.target.value.toUpperCase())}}
                                                                            type="text" 
                                                                            className='w-full border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm' 
                                                                            onKeyUp={(e)=>{tabulation(e,"categVolDep")}}/>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Pt de sortie : </span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <Select
                                                                            id="ptSortie"
                                                                            tabIndex={25}
                                                                            disabled={ptSortIsDisabled}
                                                                            {...register("ptSortie",{required:true})}
                                                                            defaultValue=""
                                                                            className='w-full min-w-full'
                                                                            size='small'
                                                                            showSearch
                                                                            allowClear={true}
                                                                            placeholder="Fir..."
                                                                            optionFilterProp="children"
                                                                            onChange={selectPtSortie}
                                                                            onSearch={onSearch}>
                                                                            {firs?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                                            })}
                                                                        </Select>
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Categ Vol : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        
                                                                        <Select
                                                                            id="categVolDep"
                                                                            tabIndex={38}
                                                                            className='w-full min-w-full text-sm'
                                                                        
                                                                            size='small'
                                                                            allowClear={true}
                                                                            showSearch
                                                                            defaultValue={{ value: 'COM', label: 'COMM' }}
                                                                            onChange={(e)=>{setCategVolDepart(e)}}
                                                                            onSearch={onSearch}
                                                                            loading={false} >
                                                                            <Option value="COM" selected>COMM</Option>
                                                                            <Option value="HUM">HUM</Option>
                                                                            <Option value="PRIV">PRIV</Option>
                                                                            <Option value="MLN">MLN</Option>
                                                                            <Option value="MLE">MLE</Option>
                                                                            <Option value="ETAT">ETAT</Option>
                                                                            <Option value="DIPL">DIPL</Option>
                                                                        </Select>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Passagers :</span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <div className="flex">
                                                                            <div className="w-1/3 text-10 text-center">AD :</div>
                                                                            <div className="w-1/3 text-10 text-center">Enf :</div>
                                                                            <div className="w-1/3 text-10 text-center">Béb :</div>
                                                                        </div>
                                                                        <div className="flex">
                                                                            <div className="w-1/3 text-10">
                                                                                <input 
                                                                                    id="paxAdDep"
                                                                                    tabIndex={26} 
                                                                                    {...register("paxAdDepart")} 
                                                                                    defaultValue="0"
                                                                                    type="number" 
                                                                                    className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-14' 
                                                                                    onKeyUp={(e)=>{tabulation(e,"paxEnfDep",true)}}/>
                                                                            </div>
                                                                            <div className="w-1/3 text-10">
                                                                                <input  
                                                                                id="paxEnfDep"
                                                                                    tabIndex="27"
                                                                                    {...register("paxEnfDepart")} 
                                                                                    defaultValue="0" 
                                                                                    type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-14' 
                                                                                    onKeyUp={(e)=>{tabulation(e,"paxBebDep",true)}}/>
                                                                            </div>
                                                                            <div className="w-1/3 text-10">
                                                                                <input  
                                                                                    id="paxBebDep"
                                                                                    tabIndex="28"
                                                                                    {...register("paxBebDepart",{required:true})} 
                                                                                    defaultValue="0" 
                                                                                    type="number" className='border py-0.5 text-10 px-2 outline-cyan-600 rounded-sm w-14' 
                                                                                    onKeyUp={(e)=>{tabulation(e,"paxCorrDep",true)}}/>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                    <td className='w-40 items-end'>
                                                                        <div className='items-end text-12 w-full text-right pr-2'> Type stat : </div>
                                                                    </td>
                                                                    <td className='w-auto'>
                                                                        <div className='flex space-x-1'>
                                                                            <div className='w-24'>
                                                                                <Select tabindex="27"
                                                                                required
                                                                                tabIndex={39}
                                                                                
                                                                                    size='small'
                                                                                    className="w-full text-sm"
                                                                                    defaultValue={{ value: 'T', label: 'T' }}
                                                                                    onChange={(e)=>{
                                                                                        selectStationnement(e);
                                                                                        setStationnement(e)
                                                                                    }}
                                                                                >
                                                                                    <Option value="T">T</Option>
                                                                                    <Option value="G">G</Option>
                                                                                    <Option value="G-T">G-T</Option>
                                                                                    <Option value="T-G-T">T-G-T</Option>
                                                                                    <Option value="ZH">ZH</Option>
                                                                                    <Option value="ZH-T">ZH-T</Option>
                                                                                    <Option value="T-ZH">T-ZH</Option>
                                                                                    <Option value="T-ZH-T">T-ZH-T</Option>
                                                                                </Select>
                                                                            </div>
                                                                            {showHT && (
                                                                            <div className='w-14 text-sm'>
                                                                                <div className='flex space-x-1'>
                                                                                    <span> HT</span>
                                                                                    <input 
                                                                                        id="ht"
                                                                                        disabled={true}
                                                                                        type="text" 
                                                                                        {...register("ht")}
                                                                                        className='w-full h-6 border py-0.5 text-10 px-1 outline-cyan-600 rounded-sm' />
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                            {showHG && (
                                                                            <div className='w-14 text-sm'>
                                                                                <div className='flex space-x-1'>
                                                                                    <span> HG</span>
                                                                                    <input disabled={true} id="hg" {...register("hg")} type="text" className='w-full h-6 border py-0.5 text-10 px-1 outline-cyan-600 rounded-sm' />
                                                                                </div>
                                                                            </div>
                                                                            )}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>&nbsp;</span>
                                                                    </td>
                                                                    <td className="w-44">
                                                                        <div>
                                                                            <div className='flex'>
                                                                                <div className='w-1/2 text-sm'>Corresp</div>
                                                                                <div className='w-1/2 text-sm'>Trans</div>
                                                                            </div>

                                                                        </div>
                                                                        <div>
                                                                            <div className='flex space-x-1'>
                                                                                <div className='w-1/2 text-sm'>
                                                                                    <input 
                                                                                        tabIndex={29}
                                                                                        id="paxCorrDep" 
                                                                                        {...register("paxCorresp")} 
                                                                                        defaultValue="0"
                                                                                        type="number" className='border w-full' 
                                                                                        onKeyUp={(e)=>{tabulation(e,"paxTransDep",true)}}/>
                                                                                </div>
                                                                                <div className='w-1/2 text-sm'>
                                                                                    <input  
                                                                                        id="paxTransDep"
                                                                                        tabIndex={30} 
                                                                                        {...register("paxTrans")} 
                                                                                        defaultValue="0"
                                                                                        type="number" className='border w-full' 
                                                                                        onKeyUp={(e)=>{tabulation(e,"ft",true)}}/>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </td>
                                                                    <td className='w-auto items-end pl-14' colSpan="3">

                                                                    </td>

                                                                </tr>
                                                                <tr>
                                                                    <td className={wrapperLabel}>
                                                                        <span>Nbre FT : </span>
                                                                    </td>
                                                                    <td className="">
                                                                        <div className='flex space-x-1 mt-1'>
                                                                            <div className='w-1/3'>
                                                                                <input 
                                                                                    id="ft"
                                                                                    tabIndex={31}
                                                                                    type="number"
                                                                                    {...register("ft")} 
                                                                                    onBlur={(e)=>{if(this.value=="") this.value="2"}}
                                                                                    defaultValue="2" className="border h-5 px-1 w-full text-10" 
                                                                                    onKeyUp={(e)=>{tabulation(e,"compt",true)}}/>
                                                                            </div>
                                                                            <div className='text-sm w-1/3'>
                                                                                <div className='text-right'>
                                                                                    Compt :
                                                                                </div>
                                                                            </div>

                                                                            <div className='w-1/3'>
                                                                                <input  
                                                                                    id="compt"
                                                                                    tabIndex={32} 
                                                                                    {...register("compt",{required:true})} 
                                                                                    type="number"
                                                                                    defaultValue={0}
                                                                                    className="border h-5 px-1 w-full text-10" 
                                                                                    onKeyUp={(e)=>{tabulation(e,"fretDep",true)}}/>
                                                                            </div>
                                                                        </div>

                                                                    </td>
                                                                    <td className='w-auto items-end pl-14' colSpan="3">
                                                                        <div className='flex space-x-7'>
                                                                            <div className='items-end text-12 text-right pr-2 flex'>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label text-sm">
                                                                                        <input 
                                                                                        id="chkAjoutEscDep"
                                                                                        tabIndex={40} className='mr-1' 
                                                                                        onChange={
                                                                                                (e) => 
                                                                                                { 
                                                                                                    if(e.target.checked){ 
                                                                                                        setShowDepartEscalesContainer(true); 
                                                                                                    }else
                                                                                                    {
                                                                                                        setShowEsc2Dep(false);
                                                                                                        setShowEsc3Dep(false);
                                                                                                        setShowEsc4Dep(false);
                                                                                                        setShowDepartEscalesContainer(false)
                                                                                                    }
                                                                                                }
                                                                                            } 
                                                                                        type="checkbox" checked={_checkEscaleDepart} />
                                                                                        Ajout escales
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='items-end text-12 text-right pr-2 flex'>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label text-sm">
                                                                                        <input 
                                                                                            tabIndex={41} 
                                                                                            {...register("balisageDepart")}
                                                                                            type="checkbox" 
                                                                                            className="mr-1"  />
                                                                                            Balisage
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div className='items-end text-12 text-right pr-2 flex'>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label text-sm">
                                                                                        <input 
                                                                                            tabIndex={42}
                                                                                            {...register("aisDepart")} 
                                                                                            type="checkbox" 
                                                                                            className="mr-1" />
                                                                                        Assistance AIS
                                                                                    </label>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div className='w-20 align-middle m-auto'>
                                                        <div>
                                                            <div className='text-10 mb-3 items-center text-center'>
                                                                Total pax:
                                                            </div>
                                                            <div>
                                                                <div className='flex w-40'>
                                                                    <div className='text-10 w-12 text-10'>
                                                                        Nat
                                                                    </div>
                                                                    <div className='flex w-12'>
                                                                        <input type="text" className='border rounded-sm h-5 w-full italic text-sm p-1' defaultValue="0" disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='flex w-40'>
                                                                    <div className='text-10 w-12 text-10'>
                                                                        Int
                                                                    </div>
                                                                    <div className='flex w-12'>
                                                                        <input type="text" className='border rounded-sm h-5 w-full italic text-sm p-1' defaultValue="0" disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-6'>
                                                            <div className='text-10 mb-3 items-center text-center'>
                                                                Total fret:
                                                            </div>
                                                            <div>
                                                                <div className='flex w-40'>
                                                                    <div className='text-10 w-12 text-10'>
                                                                        Nat
                                                                    </div>
                                                                    <div className='flex w-12'>
                                                                        <input type="text" className='border rounded-sm h-5 w-full italic text-sm p-1' defaultValue="0" disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className='flex w-40'>
                                                                    <div className='text-10 w-12 text-10'>
                                                                        Int
                                                                    </div>
                                                                    <div className='flex w-12'>
                                                                        <input type="text" className='border rounded-sm h-5 w-full italic text-sm p-1' defaultValue="0" disabled={true} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='flex space-x-2'>
                                                <div className='flex w-800'>
                                                    <div className='w-600'>
                                                        <div className='h-0.5'>&nbsp;</div>
                                                        {/* ESPACE ESCALE */}
                                                        {
                                                            showDepartEscalesContainer && (
                                                            <div className='w-auto truncate border rounded-sm bg-gray-50 pt-2 space-x-2 mt-3'>
                                                                <div className='flex space-x-0.5'>
                                                                    <div className="w-7 text-10"> &nbsp;</div>
                                                                    <div className="w-48 text-10"> Escales : </div>
                                                                    <div className="w-32 text-10"> Adultes : </div>
                                                                    <div className="w-30 text-10"> Enfants : </div>
                                                                    <div className="w-32 text-10"> Bébés : </div>
                                                                    <div className="w-32 text-10"> Pax Trans : </div>
                                                                    <div className="w-32 text-10"> Fret Kg : </div>
                                                                    <div className="w-32 text-10"> Exc Bag : </div>
                                                                    <div className="w-32 text-10 "> Fret Trans : </div>
                                                                    <div className="w-32 text-10 "> Post : </div>
                                                                    <div className="w-32 text-10 "> &nbsp; </div>
                                                                </div>
                                                            
                                                                <div className='flex pb-3 space-x-1 pr-1'>
                                                                    <div className="w-7 text-10"> 1 :</div>
                                                                    <div className="w-36 text-10">
                                                                        <Select
                                                                            {...register("firEsc1Depart")}
                                                                            className='w-full min-w-full text-sm'
                                                                            size='small'
                                                                            showSearch
                                                                            allowClear={true}
                                                                            placeholder="Fir..."
                                                                            optionFilterProp="children"
                                                                            onSearch={onSearch}
                                                                            onChange={(e)=>{
                                                                                setEsc1FirD(e);
                                                                                setVilles2D(villes1D.filter(v=>{return v.IdFir!=e}));
                                                                                e!=="" && document.querySelector("#paxAdEsc1Dep").focus();
                                                                            }}
                                                                            onKeyUp={(e)=>{document.querySelector("#paxAdEsc1Dep").focus();}}
                                                                        >
                                                                            {villes1D?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                    <div className="w-24 text-10"> <input id="paxAdEsc1Dep" type="text" {...register("adEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxEnfEsc1Dep",true); return false}}
                                                                        /> </div>
                                                                    <div className="w-24 text-10"> <input id="paxEnfEsc1Dep" type="text" {...register("enfEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxBebEsc1Dep",true)}}
                                                                        /> </div>
                                                                    <div className="w-24 text-10"> <input id="paxBebEsc1Dep" type="text" {...register("bebEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxTransEsc1Dep",true)}}
                                                                        /> </div>
                                                                    <div className="w-24 text-10"> <input id="paxTransEsc1Dep" type="text" {...register("paxTransEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretEsc1Dep",true)}}
                                                                        />  </div>
                                                                    <div className="w-24 text-10"> <input id="fretEsc1Dep" type="text" {...register("fretKgEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' 
                                                                            onKeyUp={(e)=>{tabulation(e,"excBagEsc1Dep",true)}}
                                                                        />  </div>
                                                                    <div className="w-24 text-10"> <input id="excBagEsc1Dep" type="text" {...register("excBagEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretTransEsc1Dep",true)}}
                                                                        />  </div>
                                                                    <div className="w-24 text-10"> <input id="fretTransEsc1Dep" type="text" {...register("fretTransEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' 
                                                                            onKeyUp={(e)=>{tabulation(e,"postEsc1Dep",true)}}
                                                                        />  </div>
                                                                    <div className="w-24 text-10"> <input id="postEsc1Dep" type="text" {...register("postEsc1Depart")} defaultValue={0} className='w-full border px-1 h-6 text-small' /> </div>
                                                                    <div className="w-10 text-10"> 
                                                                        <button onClick={() => 
                                                                            { 
                                                                                esc1FirD!==""? setShowEsc2Dep(true):
                                                                                notification['error']({
                                                                                    message:'Erreur',
                                                                                    description:"Veuillez selectionner l'escale"
                                                                                });
                                                                            }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>+</button>
                                                                    </div>
                                                                    <div className="w-10 text-10"> 
                                                                        <button type="button" onClick={() => { 

                                                                            setShowDepartEscalesContainer(false); 
                                                                            document.querySelector("#chkAjoutEscDep").checked=false;
                                                                            setEsc1FirD("");
                                                                            setValueHook("firEsc1Depart","");
                                                                            setValueHook("adEsc1Depart",0);
                                                                            setValueHook("enfEsc1Depart","0");
                                                                            setValueHook("bebEsc1Depart","0");
                                                                            setValueHook("paxTransEsc1Depart","0");
                                                                            setValueHook("fretKgEsc1Depart","0");
                                                                            setValueHook("excBagEsc1Depart","0");
                                                                            setValueHook("fretTransEsc1Depart","0");
                                                                            setValueHook("postEsc1Depart","0");

                                                                        }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button></div>
                                                                </div>
                                                                {
                                                                    showEsc2Dep && (
                                                                    <div id='' className='flex pb-3 space-x-1 pr-1'>
                                                                    <div className="w-7 text-10"> 2 :</div>
                                                                    <div className="w-36 text-10">
                                                                        <Select
                                                                            className='w-full min-w-full text-sm'
                                                                            {...register("firEsc2Depart")}
                                                                            size='small'
                                                                            showSearch
                                                                            allowClear={true}
                                                                            placeholder="Fir..."
                                                                            optionFilterProp="children"
                                                                            onSearch={onSearch}
                                                                            onChange={(e)=>{
                                                                                setEsc2FirD(e);
                                                                                setVilles3D(villes2D.filter(v=>{return v.IdFir!=e}));
                                                                            }}    
                                                                        >
                                                                            {villes2D?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                                            })}
                                                                        </Select>
                                                                    </div>
                                                                    <div className="w-24 text-10"> <input id="paxAdEsc2Dep" type="text" {...register("adEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                        onKeyUp={(e)=>{tabulation(e,"paxEnfEsc2Dep",true)}} /> </div>
                                                                    <div className="w-24 text-10"> <input id="paxEnfEsc2Dep" type="text" {...register("enfEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                        onKeyUp={(e)=>{tabulation(e,"paxBebEsc2Dep",true)}} /> </div>
                                                                    <div className="w-24 text-10"> <input id="paxBebEsc2Dep" type="text" {...register("bebEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                        onKeyUp={(e)=>{tabulation(e,"paxTransEsc2Dep",true)}} /> </div>
                                                                    <div className="w-24 text-10"> <input id="paxTransEsc2Dep" type="text" {...register("paxTransEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                        onKeyUp={(e)=>{tabulation(e,"fretKgEsc2Dep",true)}} />  </div>
                                                                    <div className="w-24 text-10"> <input id="fretKgEsc2Dep" type="text" {...register("fretKgEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                        onKeyUp={(e)=>{tabulation(e,"excBagEsc2Dep",true)}} />  </div>
                                                                    <div className="w-24 text-10"> <input id="excBagEsc2Dep" type="text" {...register("excBagEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                        onKeyUp={(e)=>{tabulation(e,"fretTransEsc2Dep",true)}} />  </div>
                                                                    <div className="w-24 text-10"> <input id="fretTransEsc2Dep" type="text" {...register("fretTransEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                        onKeyUp={(e)=>{tabulation(e,"postEsc2Dep",true)}} />  </div>
                                                                    <div className="w-24 text-10"> <input id="postEsc2Dep" type="text" {...register("postEsc2Depart")} defaultValue={0} className='w-full border px-1 h-6' /> </div>
                                                                    <div className="w-10 text-10"> 
                                                                        <button onClick={() => 
                                                                            { 
                                                                                
                                                                                    esc2FirD!==""? setShowEsc3Dep(true):
                                                                                    notification['error']({
                                                                                        message:'Erreur',
                                                                                        description:"Veuillez selectionner l'escale"
                                                                                    });
                                                                                
                                                                                
                                                                            }} 
                                                                        className='w-8 bg-blue-500 h-6 rounded-sm text-white'>+</button></div>
                                                                    <div className="w-10 text-10"> 
                                                                        <button type="button" onClick={() => 
                                                                            { 
                                                                                setShowEsc2Dep(false); 
                                                                                setEsc2FirD("");
                                                                                setValueHook("adEsc2Depart",0);
                                                                                setValueHook("enfEsc2Depart","0");
                                                                                setValueHook("bebEsc2Depart","0");
                                                                                setValueHook("paxTransEsc2Depart","0");
                                                                                setValueHook("fretKgEsc2Depart","0");
                                                                                setValueHook("excBagEsc2Depart","0");
                                                                                setValueHook("fretTransEsc2Depart","0");
                                                                                setValueHook("postEsc2Depart","0");
                                                                            }} 
                                                                            className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button></div>
                                                                    </div>
                                                                    )
                                                                }
                                                                {
                                                                    showEsc3Dep && (
                                                                    <div  className='flex pb-3 space-x-1 pr-1'>
                                                                        <div className="w-7 text-10"> 3 :</div>
                                                                        <div className="w-36 text-10">
                                                                            <Select
                                                                                {...register("firEsc3Depart")}
                                                                                className='w-full min-w-full text-sm'
                                                                                size='small'
                                                                                showSearch
                                                                                allowClear={true}
                                                                                placeholder="Fir..."
                                                                                optionFilterProp="children"
                                                                                onSearch={onSearch}
                                                                                onChange={(e)=>{
                                                                                    setEsc3FirD(e);
                                                                                    setVilles4D(villes3D.filter(v=>{return v.IdFir!=e}));
                                                                                }}
                                                                            >
                                                                                {villes3D?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                    return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                    <div className="w-24 text-10"> <input id="paxAdEsc3Dep" type="text" {...register("adEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxEnfEsc3Dep",true)}} /> </div>
                                                                        <div className="w-24 text-10"> <input id="paxEnfEsc3Dep" type="text" {...register("enfEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxBebEsc3Dep",true)}} /> </div>
                                                                        <div className="w-24 text-10"> <input id="paxBebEsc3Dep" type="text" {...register("bebEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxTransEsc3Dep",true)}} /> </div>
                                                                        <div className="w-24 text-10"> <input id="paxTransEsc3Dep" type="text" {...register("paxTransEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretKgEsc3Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="fretKgEsc3Dep" type="text" {...register("fretKgEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"excBagEsc3Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="excBagEsc3Dep" type="text" {...register("excBagEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretTransEsc3Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="fretTransEsc3Dep" type="text" {...register("fretTransEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"postEsc3Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="postEsc3Dep" type="text" {...register("postEsc3Depart")} defaultValue={0} className='w-full border px-1 h-6' /> </div>                                                                    <div className="w-10 text-10"> 
                                                                            <button type="button" onClick={() => 
                                                                                { 
                                                                                
                                                                                    esc3FirD!==""? setShowEsc4Dep(true):
                                                                                    notification['error']({
                                                                                        message:'Erreur',
                                                                                        description:"Veuillez selectionner l'escale"
                                                                                    });
                                                                                }} 
                                                                                className='w-8 bg-blue-500 h-6 rounded-sm text-white'>+</button></div>
                                                                        <div className="w-10 text-10"> 
                                                                        <button type="button" onClick={() => { 
                                                                            setShowEsc3Dep(false); 
                                                                                setEsc3FirD("");
                                                                                setValueHook("adEsc3Depart",0);
                                                                                setValueHook("enfEsc3Depart","0");
                                                                                setValueHook("bebEsc3Depart","0");
                                                                                setValueHook("paxTransEsc3Depart","0");
                                                                                setValueHook("fretKgEsc3Depart","0");
                                                                                setValueHook("excBagEsc3Depart","0");
                                                                                setValueHook("fretTransEsc3Depart","0");
                                                                                setValueHook("postEsc3Depart","0");
                                                                        }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button></div>
                                                                    </div>)
                                                                }
                                                                {
                                                                    showEsc4Dep && (
                                                                    <div  className='flex pb-3 space-x-1 pr-1'>
                                                                        <div className="w-7 text-10"> 4 :</div>
                                                                        <div className="w-36 text-10">
                                                                            <Select
                                                                                {...register("firEsc4Depart")}
                                                                                className='w-full min-w-full text-sm'
                                                                                size='small'
                                                                                showSearch
                                                                                allowClear={true}
                                                                                placeholder="Fir..."
                                                                                optionFilterProp="children"
                                                                                onSearch={onSearch}
                                                                                onChange={(e)=>{
                                                                                    setEsc4FirD(e);
                                                                                }}
                                                                            >
                                                                                {villes4D?.sort((v1, v2) => { return v1.CodeFir > v2.CodeFir }).map(i => {

                                                                                    return (<Option key={i.IdFir} value={i.IdFir}>{i.CodeFir}</Option>)
                                                                                })}
                                                                            </Select>
                                                                        </div>
                                                                        <div className="w-24 text-10"> <input id="paxAdEsc4Dep" type="text" {...register("adEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxEnfEsc4Dep",true)}} /> </div>
                                                                        <div className="w-24 text-10"> <input id="paxEnfEsc4Dep" type="text" {...register("enfEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxBebEsc4Dep",true)}} /> </div>
                                                                        <div className="w-24 text-10"> <input id="paxBebEsc4Dep" type="text" {...register("bebEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"paxTransEsc4Dep",true)}} /> </div>
                                                                        <div className="w-24 text-10"> <input id="paxTransEsc4Dep" type="text" {...register("paxTransEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretKgEsc4Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="fretKgEsc4Dep" type="text" {...register("fretKgEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"excBagEsc4Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="excBagEsc4Dep" type="text" {...register("excBagEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"fretTransEsc4Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="fretTransEsc4Dep" type="text" {...register("fretTransEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' 
                                                                            onKeyUp={(e)=>{tabulation(e,"postEsc4Dep",true)}} />  </div>
                                                                        <div className="w-24 text-10"> <input id="postEsc4Dep" type="text" {...register("postEsc4Depart")} defaultValue={0} className='w-full border px-1 h-6' /> </div>                                                                    
                                                                        <div className="w-10 text-10"> <button type="button" onClick={() => {  }} className='w-8 bg-blue-500 h-6 rounded-sm text-white'>-</button></div>
                                                                            <div className="w-10 text-10"> &nbsp;</div>
                                                                        </div>)
                                                                }
                                                            </div>)
                                                        }
                                                        {/* FIN ESCALE */}
                                                    </div>
                                                    {/* ZONE HEURE STATIONNEMENT */}
                                                    <div className='w-200 ml-1 border rounded-sm p-1 mt-3'>
                                                        {showStationnementEnt &&
                                                        <div className=''>
                                                            <h6 className='text-10'>Date Entr Garage </h6>
                                                            <div className='flex'>
                                                                <div className='w-auto'>
                                                                    <input type="date" {...register("dtEntreeGarage")} className='w-full border text-sm' />
                                                                </div>
                                                                <div className='w-1/3 text-center items-center'>
                                                                    <span className='w-auto text-11'> à : </span>
                                                                </div>
                                                                <div className='w-1/3'>
                                                                    <input type="time" {...register("heureEntreeGarage")} className='w-full border text-sm' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                        {showStationnementSort &&
                                                        <div className='mt mt-3'>
                                                            <h6 className='text-10'>Date Sortie Garage </h6>
                                                            <div className='flex'>
                                                                <div className='w-auto'>
                                                                    <input type="date" {...register("dtSortieGarage")} className='w-full border text-sm' />
                                                                </div>
                                                                <div className='w-1/3 text-center items-center'>
                                                                    <span className='w-auto text-11'> à : </span>
                                                                </div>
                                                                <div className='w-1/3'>
                                                                    <input type="time" {...register("heureSortieGarage")} className='w-full border text-sm' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='w-36 mt-2 space-x-2 border rounded-sm pr-1 pb-1'>
                                                <div className='flex'>
                                                    <div className='w-1/2 text-sm pl-4'>Levée :</div>
                                                    <div className='w-1/2 text-sm pl-4'>Couchée: </div>
                                                </div>
                                                <div className='flex space-x-1'>
                                                    <div className='w-1/2'><input type="text" {...register("leveeSoleilD")} className='h-[25px] w-full border text-[11px] font-bold' disabled={true} /></div>
                                                    <div className='w-1/2'><input type="text"  {...register("coucheeSoleilD")} className='h-[25px] w-full border text-[11px] font-bold' disabled={true} /></div>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={value} index={2}>
                                            <MuiAlert severity="warning">
                                                <AlertTitle><strong>Gestion intelligente d'erreur</strong></AlertTitle>
                                                Vous devez d'abord remplir votre formulaire en arrivée et au depart 
                                                pour nous permettre de vous dire les erreurs
                                            </MuiAlert>                  
                                        </TabPanel>
                                    </Box>
                                    <Box>
                                        <div className="flex justify-between p-2 pt-1 pr-4">
                                            <div className='w-36 ml-4 space-x-2  pr-1 pb-1'>

                                            </div>
                                            <div className='flex items-end space-x-2'>
                                                <button type='reset' className="bg-blue-400 h-10 rounded-sm text-12 text-white px-2 w-28 hover:bg-blue-600 hover:shadow-md">
                                                    <CloseIcon /> 
                                                    Annuler 
                                                </button>
                                                <button type="button" onClick={()=>onSubmit()} className={"bg-blue-400 h-10 rounded-sm  text-12 text-white px-2 w-28 hover:bg-blue-600 hover:shadow-md"}>
                                                    <DoneAllIcon /> 
                                                    Enregistrer
                                                </button>
                                            </div>
                                        </div>
                                    </Box>
                                </Paper>
                            </form>
                        </div>
                    </div>

                </div>
            )}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}>
                <CircularProgress color="inherit" /><br />
                <span>Traitement en cours... </span>
            </Backdrop>
        </>
    );
}
