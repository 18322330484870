import React,{useState} from "react";
import "./../assets/css/bootstrap.min.css";
import "./../assets/css/w3.css";
import logoRva from "./../images/logoRva.png";
import InsightsIcon from "@mui/icons-material/Insights";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useStateContext } from './../contexts/ContextProvider';


//{/*<link rel="stylesheet" type="text/css" href="libs/materialx/assets/css/material-icons.css" />
//<link rel="stylesheet" type="text/css" href="libs/materialx/assets/css/materialX.css" />*/}


import "./../assets/css/typography.css";
import "./../assets/css/default-css.css";

import "./../assets/css/responsive.css";
import { CircularProgress } from '@mui/material';

const Login = () => {
    const { api,setConnected } = useStateContext();
    const [submiting,setSubmiting]=useState(false);
    const [login,setLogin]=useState("");
    const [password,setPassword]=useState();
    const [feedBackMsg,setFeedBackMsg]=useState("");
    
    const handleSubmit=(e)=>{
        setFeedBackMsg("");
        setSubmiting(true);
        let data=new FormData();
        data.append("qry","login");
        data.append("login",login);
        data.append("password",password);
        fetch(api,{
            method: "POST",body:data}).then(r=>r.json())
            .then(r=>{
                
                if(r.resultat===0)
                {
                    setFeedBackMsg("Désolé, utilisateur non réconnu");
                }else if(r.resultat===1)
                {
                    const userProfil=JSON.stringify(r.data);
                    localStorage.setItem("userProfil",userProfil);
                    localStorage.setItem("connected","true");
                    setConnected(true);
                }else{
                    setFeedBackMsg("Désolé, nous n'avons pu interpreter la réponse du serveur");
                }
                setSubmiting(false);
            }).catch(r=>{
                setSubmiting(false);
                setFeedBackMsg("une erreur s'est produite dans le système, veuillez reesayer plutard");
                console.log(r);
            });
        e.preventDefault();
    };
  return (
    <div className={`h-full bg-[#0f0f40]`}>
      <div
        className="login-area h-screen"
        style={{  }}
      >
        <div
          className={`w3-center pt-3 shadow-md justify-center text-center flex flex-col bg-[#303c6c]`}
          style={{
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          
            <h6 className="w3-large w3-text-cyan text-center justify-center">
                REPUBLIQUE DEMOCRATIQUE DU CONGO
            </h6>
            <img src={logoRva} width={200} style={{maxWidth: "170px"}} className="m-auto" alt="" />
            <h6 className="w3-text-white w3-bold w3-large">
                REGIE DES VOIES AERIENNES
            </h6>
        </div>
        <div className="flex justify-center" style={{marginTop: "6Opx"}}>
          <div
            className="login-box ptb--20"
            style={{marginTop: "-85px", width:"500px"}}>
            <form id="" className="rounded-lg" onSubmit={handleSubmit}>
              <div
                className=" bg-[]"
                style={{ paddingBottom: "-20px" }}
              >
                <h4 className="w3-text-cyan yoo-font-size-17 text-bold text-raleway font-bold bg-[#3F51AE] justify-center text-center" 
                    style={{marginTop:"120px",fontFamily:"Raleway"}}>

                    <br />
                    <InsightsIcon size="lg" /> &nbsp;
                    STATISTIQUES AERONAUTIQUE<br />
                  <br />
                </h4>
              </div>
              <div className="bg-white -mt-4 px-10">
                <h4 className="w3-medium w3-center pb--15 text-lg-right pt-8">
                  {/*<img src="assets/images/aips.png" alt="" style={{maxWidth: "80px"}} />*/}
                  {/* <AccountCircleIcon size="lg" /> SE CONNECTER */}
                  {/* <StarFilled spin={2} fill={"true"} />  */}
                  SE CONNECTER
                </h4>
                <div className="yoo-form-field-wrap yoo-style1">
                  <div className="input-group form-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <UserOutlined />
                      </span>
                    </div>
                    <input
                        onChange={(e)=>setLogin(e.target.value)}
                      type="text"
                      className="form-control outline-none"
                      placeholder="Nom utilisateur"
                      id="fCode"
                      required
                      style={{"outline":"none"}}
                    />
                  </div>
                </div>
                <br />
                <div className="yoo-form-field-wrap yoo-style1">
                  <div className="input-group form-group-sm">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        <LockOutlined />
                      </span>
                    </div>
                    <input
                        onChange={(e)=>setPassword(e.target.value)}
                      type="password"
                      className="form-control"
                      placeholder="Mot de passe"
                      id="fPassword"
                      required
                    />
                  </div>
                </div>
                <br />
                {
                    submiting?(
                        <div>
                            <div className="w3-center rmber-area mb-10 bg-blue-300 rounded-full py-2 flex flex-row justify-center" id="wrapperAlert">
                                <CircularProgress size={25} />
                                <span className="mt-1 ml-3">
                                    Connexion en cours...
                                </span>
                            </div>
                            <br />
                        </div>
                    ):(
                        <div>
                            <div className="submit-btn-area text-center">
                                <h5>{feedBackMsg}</h5>
                                <button id="form_submit" className="bg-blue-400 h-11 hover:bg-blue-600 flex flex-row justify-center pt-2 w-36 rounded-full text-white m-auto " type="submit">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                        <path fillRule="evenodd" d="M16.403 12.652a3 3 0 000-5.304 3 3 0 00-3.75-3.751 3 3 0 00-5.305 0 3 3 0 00-3.751 3.75 3 3 0 000 5.305 3 3 0 003.75 3.751 3 3 0 005.305 0 3 3 0 003.751-3.75zm-2.546-4.46a.75.75 0 00-1.214-.883l-3.483 4.79-1.88-1.88a.75.75 0 10-1.06 1.061l2.5 2.5a.75.75 0 001.137-.089l4-5.5z" clipRule="evenodd" />
                                </svg>
                                Connexion 
                                </button>
                            </div>
                            <div className="form-footer text-center mt-3 pb-10" >
                                <div className="text-center">
                                    <a href="#" className="underline">
                                        Mot de passe oublié?
                                    </a>
                                </div>
                            </div>
                        </div>
                    )
                }
                
              </div>
            </form>
          </div>
        </div>
        <div
          className="w3-center text-white"
          style={{
            
            left: "45%",
            fontSize: "12px",
          }}
        >
          &copy; copyright 2022 - RVA / RDC
        </div>
      </div>
    </div>
  );
};

export default Login;
