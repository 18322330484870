import React from 'react'
import { Link,useNavigate } from 'react-router-dom';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import { useStateContext } from '../contexts/ContextProvider';
import {Modal} from 'antd'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import tdl from '../images/tdl.png'


const Header = () => {
    const {setConnected}=useStateContext();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const navigate=useNavigate();
    const handleClose = () => {
    };
    const deconnexion=() => {
        navigate("/");
        setConnected(false);
        localStorage.clear();
    }
    return (
        <div className='border-b-1 flex-auto border-gray-100 bg-blue-500 shadow-md h-16 text-white p-4 pr-1 justify-between flex'>
            <div className="flex space-x-2">

                <AirplanemodeActiveIcon className='text-blue-500' />
                <div className="flex space-x-3 flex-auto justify-center">
                    <span className='text-white text-md text-montserrat'>Gestion Statistique Aérienne </span>
                    <span className='bg-gray-100 text-gray-500 text-sm rounded-md h-5 px-1 '>v 1.0.0 </span>
                </div>
            </div>
            <div className='flex space-x-2'>

                <div className='flex space-x-4'>
                    <Link to='/' className='justify-center flex text-14 flex-col -mt-2 items-center p-1 hover:bg-gray-100 rounded-md hover:p-1 text-blue-300 '>

                        {/* <svg style="width:14px" xmlns="http://www.w3.org/2000/svg" className="h-16 w-14 text-blue-300 text-14" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" />
                        </svg> */}
                        <span className='text-14'>
                            Accueil
                        </span>
                    </Link>
                    <div>
                        <a href='#'>

                            <span className='flex shadow-sm justify-items-start hover:bg-gray-100 py-1 rounded-full border border-gray-100 -mt-1 px-4'
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                            >
                                <img src={tdl} className='w-6 rounded-full' alt='Profil' />
                                <span className='ml-2 text-black text-roboto'>Profile </span>
                            </span>
                        </a>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem>Profile</MenuItem>
                            <MenuItem>Parametres</MenuItem>
                            <MenuItem onClick={()=>{
                                deconnexion()
                            }}>Deconnexion</MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header;