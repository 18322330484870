import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import logoRva from "../images/logoRva.png";

import tdl from "../images/tdl.png";
import { Menu } from "antd";
import { SettingOutlined,HomeOutlined,FileTextOutlined } from "@ant-design/icons";
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';

import { Scrollbars } from "react-custom-scrollbars";
const Sidebar = () => {
  const [profilUser, setProfilUser] = useState({});
  
  useEffect(() => {
    setProfilUser(JSON.parse(localStorage.getItem("userProfil")));
  }, []);
  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    
    getItem("Accueil", "home",<HomeOutlined />),
    getItem("Mouvement", "sub2", <FlightTakeoffIcon fontSize="large" />, [
      getItem("Saisie mouvement", "saisie"),
      getItem("Liste mvts", "listeMouvements"),
    ]),
    getItem("Rapports", "sub3", <FileTextOutlined />, [
      getItem("RMA", "rma"),
      getItem("Navex 101", "rapportNavex101"),
      getItem("Statistiques Pax et Fret", "rapportPaxFret"),
      getItem("Statistique par Route", "rapportRoute"),
      getItem("Navex 110", "rapportNavex110"),
    ]),
   
    getItem("Parametres", "sub5", <SettingOutlined />, [
      getItem("Gestion des utilisateurs", "utilisateurs"),
      getItem("Gestion des entités", "entite"),
      getItem("Point FIR", "fir"),
      getItem("Gestion client", "client"),
      getItem("Aeronefs", "aeronef"),
    ])
  ];
  const navigate=useNavigate();
  const onClick = (e) => {
    // console.clear();
    // console.log("click", e);
    navigate(`/${e.key}`);
  };
  return (
    <Scrollbars
      autoHide={true}
      autoHideTimeout={1000}
      autoHideDuration={200}
      autoHeight
      autoHeightMin={"100%"}
      autoHeightMax={"100%"}
      thumbMinSize={50}
      universal={true}
      className="w-fit flex flex-col justify-between content-start bg-[#001528]"
    >
      <div className="shadow-mds">
        <div className="content-center justify-center flex">
          <img src={logoRva} className="w-30" alt="logo" />
        </div>
        <div className="flex flex-row justify-center space-x-4 pb-2">
          {/* <strong className="text-white">Régie des Voies Aeriennes</strong> */}
          {/* <span className='bg-gray-100 text-gray-500 text-sm rounded-md p-0.5 px-1 '>v 1.0.0 </span> */}
        </div>
        <div className="flex flex-row space-x-4 pb-2 align-middle bg-[#303C6C]">
          <div className="p-2 rounded-full bg-gray-200 w-14 h-14 mt-3 ml-3 shadow-sm">
            <img className=" w-full rounded-full " src={tdl} alt="test" />
          </div>
          <div className="text-center justify-center flex flex-col">
            <span className="flex-none text-lg rounded-md p-0.5 px-1 text-cyan-600">
              {profilUser.nom}
            </span>
          </div>
        </div>

        <div className="pt-4">
          {/* <ul>
                    {menus.map((m, index) => {

                        return (<div key={index}>
                            <li className='text-gray-500 text-11 mb-2 mt-2'>{m.menu}</li>
                            {m.smenu.map((sm, index2) => {

                                return (
                                    <li key={index + "/" + index2} className='h-10 w-[350px] list-none -ml-[20px]'>
                                        <NavLink to={sm.lien} 
                                            className={({ isActive }) => (isActive ? MENU_ACTIVE : MENU_INACTIF)} 
                                            onClick={()=>{
                                                if(window.localStorage.getItem("currentMenu")){
                                                    if(window.localStorage.getItem("currentMenu")==sm.lien)
                                                    {
                                                        window.location.reload();
                                                    }else
                                                    {
                                                        window.localStorage.setItem("currentMenu",sm.lien);
                                                    }
                                                }else
                                                {
                                                    window.localStorage.setItem("currentMenu",sm.lien);
                                                }
                                            }} state={{windowState:"opened"}}>
                                            <AutoAwesomeMosaicIcon fontSize='10' />
                                            <span className=''>
                                                {sm.titre}
                                            </span>
                                        </NavLink>
                                    </li>
                                );

                            })}
                        </div>
                        );
                    })
                    }
                </ul> */}
          <Menu
            onClick={onClick}
            className="mt-8"
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["4"]}
            items={items}
          />
        </div>
      </div>
    </Scrollbars>
  );
};

export default Sidebar;
