import './App.css';
import Index from "./pages/Index";
import { ContextProvider } from './contexts/ContextProvider';

const App = () => {
  
  return (
    <ContextProvider>
      <Index />
    </ContextProvider>
  );
}

export default App;
