import React, { useState, useEffect, useContext, createContext } from 'react'

const StateContext = createContext();

export const ContextProvider = ({ children }) => {  

    const _backend="https://oxerode.com/rva_statistique/";
    const _api = "https://oxerode.com/rva_statistique/api/post.php";
    //const _backend="http://localhost/root/rva_statistique/";
    //const _api = "http://localhost/root/rva_statistique/api/post.php";
    
    //const api = "https://tdlsoft.site/rva_statistique/api/post.php";
    //var api = "https://fin-vat.com/rva_statistique/api/post.php";
    const [hasLogged, setHasLogged] = useState(null);
    const [connected,setConnected]=useState(localStorage.getItem("connected")?true:false);
    const [nomUser, setNomUser] = useState("Diwata");
    const api = _api;
    const [backend, setBackend] = useState(_backend);
    //const [api, setApi] = useState(api);
    useEffect(() => {

    }, [])

    return (
        <StateContext.Provider value={{ hasLogged, setHasLogged, nomUser, setNomUser, api,backend,setBackend ,connected,setConnected}}>
            {children}
        </StateContext.Provider>
    );

}
export const useStateContext = () => useContext(StateContext);
