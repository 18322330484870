import React,{useState,useEffect} from 'react'
import {useStateContext } from './../contexts/ContextProvider';
import Home from "./Home";
import Login from "./../pages/Login";

const Index = () => {
  
  const {connected,setConnected}=useStateContext();
  return (
    connected===true?(<Home />):(<Login />)
  );
}

export default Index