import React, { useEffect, useState } from 'react'
import { Paper, Box } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Dashboard = () => {
    const _data = [
        {
            name: 'Page A',
            pax: 4000,
            pv: 2400,
            amt: 2400,
        },
        {
            name: 'Page B',
            pax: 3000,
            pv: 1398,
            amt: 2210,
        },
        {
            name: 'Page C',
            pax: 2000,
            pv: 9800,
            amt: 2290,
        },
        {
            name: 'Page D',
            pax: 2780,
            pv: 3908,
            amt: 2000,
        },
        {
            name: 'Page E',
            pax: 1890,
            pv: 4800,
            amt: 2181,
        },
        {
            name: 'Page F',
            pax: 2390,
            pv: 3800,
            amt: 2500,
        },
        {
            name: 'Page G',
            pax: 3490,
            pv: 4300,
            amt: 2100,
        },
    ];
    const [data, setData] = useState(_data);
    useEffect(() => {

    }, []);
    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
                <Grid xs={6} md={3}>
                    <Paper className='h-32 shadow-md p-2'>
                        <h4>Mouvements de l'année</h4>
                    </Paper>
                </Grid>
                <Grid xs={6} md={3}>
                    <Paper className='h-32 shadow-md p-2'>
                        <h4>Mouvements du Mois</h4>
                    </Paper>
                </Grid>
                <Grid xs={6} md={3}>
                    <Paper className='h-32 shadow-md p-2'>
                        <h4>Mouvements du jour</h4>
                    </Paper>
                </Grid>
                <Grid xs={6} md={3}>
                    <Paper className='h-32 shadow-md p-2'>
                        <h4></h4>
                    </Paper>
                </Grid>
            </Grid>
            {/* LIGNE 2 */}
            <Grid container spacing={2}>
                <Grid xs={6} md={8}>
                    <Paper className='h-96 shadow-md'>
                        <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                                width={500}
                                height={300}
                                data={data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="pv" fill="#8884d8" />
                                <Bar dataKey="uv" fill="#82ca9d" />
                                <Bar dataKey="amt" fill="#82ca80" />
                            </BarChart>
                        </ResponsiveContainer>
                    </Paper>
                </Grid>
                <Grid xs={6} md={4}>
                    <Paper className='h-32 shadow-md'></Paper>
                </Grid>
            </Grid>
        </Box>
    )
}

export default Dashboard