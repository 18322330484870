import React,{useState,useEffect} from 'react'
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useStateContext } from './../contexts/ContextProvider';

const Rma = () => {
    const { nomUser, api,backend } = useStateContext();
    const [dt1,setDt1]=useState();
    const [dt2,setDt2]=useState();
    const [loading,setLoading]=useState(false);
    const [resultat,setResultat]=useState(false);
    const [rapport,setRapport]=useState("");
    
    const handleSubmit=(e)=>{
        setRapport("");
        setLoading(true);
        setResultat(true);
        setRapport(backend+"views/print/main.php?query=statRoute&dt1="+dt1+"&dt2="+dt2+"&orientation=L");
        setLoading(false);
        //.html(`<iframe src='views/print/main.php?query=rma&dt1=${$("#du").val()}&dt2=${$("#au").val()}&orientation=L' style='width:100%; height:800px; border:1px solid #CCC'></iframe>`)
        e.preventDefault();
    }
    return (
    <div>
        <div>
            <div className="modals fades w3-card" id="myModals" tabindex="-1" role="dialogs" aria-labelledby="myModalLabels"
                style={{width:"100%"}}>
                <div className="modal-dialogs">
                    <div className="modal-contents p-2">
                        <div>
                            <h4 className=''>Statistiques par Route</h4>
                            <div className="w3-white w3-padding w3-round-medium">
                                <form className="form-inline w3-white" id="" onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <div className="input-group">
                                            <span className="input-group-addon mr-2">Du</span>
                                            <span className="input-group-addon"><img src="images/agenda2.png"
                                                    className="img-responsive w-100" style={{width:"20px"}} alt="" />
                                            </span>
                                            <input type="date" required className="form-control" id="du" onChange={e=>setDt1(e.target.value)} placeholder="" />
                                        </div>
                                    </div>
                                    <div className="form-group ml-3">

                                        <div className="input-group">
                                            <span className="input-group-addon mr-2">Au</span>
                                            <span className="input-group-addon"><img src="images/agenda2.png"
                                                    className="img-responsive w-100" style={{width:"20px"}} alt="" />
                                            </span>

                                            <input type="date" required className="form-control" id="au" onChange={(e)=>{setDt2(e.target.value)}} placeholder="" />
                                        </div>
                                    </div>

                                    <button type="submit" className="btn btn-info ml-4 bg-blue-400" style={{marginTop: "-5px"}}>Afficher</button>
                                </form>
                            </div>

                            <div id="result" className="w3-white w3-round-medium" style={{display: "flex",justifyContent: "center", alignItems: "center", marginTop: "15px", minHeight: "450px"}}>
                                {
                                    loading?(
                                        <h4>
                                            <AccountCircleIcon /> Traitement en cours...
                                        </h4>
                                    ):""
                                    
                                }
                                {resultat?(<iframe src={rapport} style={{width:"100%", height:"800px", border:"1px solid #CCC"}}></iframe>):""}
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Rma